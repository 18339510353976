import React, { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { useDropzone } from 'react-dropzone';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {  Paper  } from '@mui/material';

import { styled } from '@material-ui/styles';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@material-ui/icons';
import { parse, format } from 'date-fns';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import api from '../../../../../services/api';
import { Card, CardContent } from '@mui/material';
import NavegadorGestao from "../../../../../components/NavBarGestao";
import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';
import office from '../../../../../assets/imgs/office.png';
import {
    DataGrid, ptBR,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';



const dropzoneStyles = {
    border: '4px dashed #000000',
    borderRadius: '4px',
    padding: '2px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '2px 0',
};


const ColorButtonRed = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'red',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'red',
        color: 'whit',
        borderColor: 'blue',
    },
}));



const ColorButtonBlue = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'blue',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'blue',
        color: 'green',
        borderColor: 'green',
    },
}));



export default function EditarPreCotacao() {




    const onDropArquivo1 = useCallback(async (acceptedFiles) => {
        // Valida o tipo de arquivo
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf') && !acceptedFiles[0].type.includes('application/vnd.openxmlformats-officedocument')) {
            alert('Apenas arquivos PDF ou do Office são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setOpenAtualizando(true);
        try {
            const formData = new FormData();
            formData.append('arquivos', acceptedFiles[0]);
            formData.append('extensao', acceptedFiles[0].name.split('.').pop());

            const response = await api.post('/v1/protected/ordemcompras/anexararquivoprecotacao/' + idOrdemCompra + "/" + idPreCotacao, formData);

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {

                setPreCotacao(response.data);

            } else {
                // Lógica a ser executada caso ocorra algum erro durante o salvamento
            }
            setOpenAtualizando(false);
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");
            setOpenAtualizando(false);
        }
    }, []);




    const { getRootProps: getRootArquivoProps1, getInputProps: getInputArquivoProps1 } = useDropzone({
        onDrop: onDropArquivo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        //accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });


    const history = useHistory();

    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const [fornecedorSelecionado, setFornecedorSelecionado] = useState(null);

    const { idOrdemCompra } = useParams();
    const { idPreCotacao } = useParams();
    const [openAtualizando, setOpenAtualizando] = useState(false);

    const [itens, setItens] = useState([]);
    const [valorFrete, setValorFrete] = useState([]);
    const [observacoes, setObservacoes] = useState("");
    const [dataEntrega, setDataEntrega] = useState("");
    const [diasPrevistaEntrega, setDiasPrevistaEntrega] = useState("");
    const [vendedorSelecionado, setVendedorSelecionado] = useState(null);
    const [loading, setLoading] = useState(true);

    const [ordemCompra, setOrdemCompra] = useState(null);
    const [preCotacao, setPreCotacao] = useState(null);

    const [metodosPagamento, setMetodosPagamento] = useState([]);


    const [metodosPagamentosSelecionados, setMetodosPagamentosSelecionados] = useState([]);

    const [condicoesPagamento, setCondicoesPagamento] = useState([]);
    const [condicaoPagamentoSelecionada, setCondicaoPagamentoSelecionada] = useState([]);


    const [parametrosValores, setParametrosValores] = useState(
        {
            valorTotalItens: 0.0,
            valorTotalIpi: 0.0,
            valorTotalIcms: 0.0,
            valorTotalFrete: 0.0,
            valorTotal: 0.0,
        }
    );


    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/ordensdecompras/ordemdecompra/precotacao/listarporid/" + idPreCotacao, {
                headers: headers
            }).then(function (response) {

                setPreCotacao(response.data);
                setFornecedorSelecionado(response.data.fornecedor);
                setObservacoes(response.data.observacoes);
                setValorFrete(response.data.valor_frete);

                setMetodosPagamentosSelecionados(response.data.metodos_pagamento);
                setCondicaoPagamentoSelecionada(response.data.condicao_pagamento);

                setItens(response.data.itens_pre_cotacao);

                setDataEntrega(new Date(response.data.data_prevista_entrega).toLocaleDateString('pt-BR'));

                setDiasPrevistaEntrega(response.data.dias_prevista_entrega);

                setVendedorSelecionado(response.data.vendedor);

                let valor_total_itens = 0;
                let valor_total_ipi = 0;
                let valor_total_icms = 0;

                response.data.itens_pre_cotacao.forEach(item => {
                    if (item.cotacao && item.cotacao.valor_unitario && item.quantidade) {
                        valor_total_itens += item.cotacao.valor_unitario * item.quantidade;
                        valor_total_ipi += item.cotacao.valor_ipi;
                        valor_total_icms += item.cotacao.valor_icms;
                    }
                });

                setParametrosValores(prevState => ({ ...prevState, 'valorTotalItens': valor_total_itens }))
                setParametrosValores(prevState => ({ ...prevState, 'valorTotalIpi': valor_total_ipi }))
                setParametrosValores(prevState => ({ ...prevState, 'valorTotalIcms': valor_total_icms }))

                setParametrosValores(prevState => ({
                    ...prevState,
                    'valorTotal': (valor_total_itens + valor_total_ipi + valor_total_icms + response.data.valor_frete)

                }))


                api.get("/v1/protected/ordensdecompras/ordemdecompra/listarporid/" + idOrdemCompra, {
                    headers: headers
                }).then(function (response) {

                    const ordem_compra = response.data.ordem_compra;
                    setOrdemCompra(ordem_compra);






                    api.get("v1/protected/financeiro/metodospagamento/listartodos", {
                        headers: headers
                    }).then(function (response) {

                        setMetodosPagamento(response.data)


                        api.get("v1/protected/financeiro/condicoespagamento/listartodos", {
                            headers: headers
                        }).then(function (response) {

                            setCondicoesPagamento(response.data)
                            setLoading(false);

                        });


                    });
                });
            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }


    async function recalcular() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/ordensdecompras/ordemdecompra/precotacao/listarporid/" + idPreCotacao, {
                headers: headers
            }).then(function (response) {

                setItens(response.data.itens_pre_cotacao);
                let valor_total_itens = 0;
                let valor_total_ipi = 0;
                let valor_total_icms = 0;

                response.data.itens_pre_cotacao.forEach(item => {
                    if (item.cotacao && item.cotacao.valor_unitario && item.quantidade) {
                        valor_total_itens += item.cotacao.valor_unitario * item.quantidade;
                        valor_total_ipi += item.cotacao.valor_ipi;
                        valor_total_icms += item.cotacao.valor_icms;
                    }
                });

                setParametrosValores(prevState => ({ ...prevState, 'valorTotalItens': valor_total_itens }))
                setParametrosValores(prevState => ({ ...prevState, 'valorTotalIpi': valor_total_ipi }))
                setParametrosValores(prevState => ({ ...prevState, 'valorTotalIcms': valor_total_icms }))

                setParametrosValores(prevState => ({
                    ...prevState,
                    'valorTotal': (valor_total_itens + valor_total_ipi + valor_total_icms + response.data.valor_frete)

                }))
            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }

    useEffect(() => {


        listarInformacoes();


    }, []);




    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };



    const [openEditarCotacao, setOpenEditarCotacao] = useState(false);
    const [cotacaoEditar, setCotacaoEditar] = useState(null);

    function returnDescricaoFornecedor(fornecedor) {

        var identificacao = fornecedor.tipo_fornecedor === 0 ? "CPF " : "CNPJ;"

        var valor = fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj;

        var nome = fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) :
            (fornecedor.nome_fantasia);

        var endereco = fornecedor.logradouro + ", nº " + fornecedor.numero + ", " + fornecedor.bairro + ", " + fornecedor.cidade + " " + fornecedor.estado + " CEP: " + fornecedor.cep;


        var texto = "ID: " + fornecedor.id_fornecedor + "\n" + identificacao + " " + valor + "\n " + nome + "\n Endereço: " + endereco;

        texto += (fornecedor.tipo_loja === 0 ? "\nLoja Local" : "\nLoja Online");
        texto += (fornecedor.recebe_ordens === 0 ? "\nNão Recebe Ordens de Compra" : "\nRecebe Ordens de Compra");

        return texto;
    }



    function returnDescricaoContato(contato) {



        var texto = "ID: " + contato.id_contato + " Nome: " + contato.nome_contato + " Cargo: " + contato.cargo_contato;
        texto += "\nContato: " + contato.celular_contato;

        texto += (contato.whatsapp === 0 ? "" : "(Whatsapp)");

        texto += "\nFixo: " + contato.fixo_contato;
        texto += "\nE-mail: " + contato.e_mail_contato;
        texto += (contato.recebe_ordens === 0 ? "\nNão Recebe Ordens de Compra" : "\nRecebe Ordens de Compra");
        texto += "\nDescrição: " + contato.descricao_contato;


        return texto;
    }



    async function atualizar() {


        try {

            console.log("cadastrar chamado");
            const cadastro = {

                observacoes: observacoes,
                valor_frete: valorFrete,
                dias_prevista_entrega: diasPrevistaEntrega,
                vendedor: vendedorSelecionado,
                metodos_pagamento: metodosPagamentosSelecionados,
                condicao_pagamento: condicaoPagamentoSelecionada,

            }

            if (dataEntrega && parse(dataEntrega, 'dd/MM/yyyy', new Date()) instanceof Date) {
                cadastro.data_prevista_entrega = format(parse(dataEntrega, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd');
            }


            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = parseInt(Cookies.get("id_usuario"));




            const response = await api.put('/v1/protected/ordensdecompras/ordemdecompra/editarprecotacao/' + idPreCotacao + "/" + id_usuario_logado, cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Orçamento Atualizado!");

                // window.location.reload();

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }

    }


    async function handleClickRemocao(event, cellValues) {

        const id_remover = cellValues.row.id_item_ordem_compra;

        try {

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = parseInt(Cookies.get("id_usuario"));


            const response = await api.delete("/v1/protected/ordensdecompras/ordemdecompra/editarprecotacao/" + idPreCotacao + "/" + id_usuario_logado + "/removeritem/" + id_remover,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Removido!");
                listarInformacoes();


            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }

    };


    function returnDescricaoInsumo(insumo) {
        for (const variacao of insumo.variacoes) {
            if (variacao.id_fornecedor === preCotacao.fornecedor.id_fornecedor) {
                // Se encontrar uma correspondência, retorna a descrição dessa variação com "Variação" em negrito
                return <span><strong>Variação:</strong> {variacao.descricao}</span>;
            }
        }

        // Se não houver correspondência, retorna a descrição principal do insumo
        return insumo.descricao;
    }

    function handleClickEditarCotacao(event, cellValues) {

        var id = cellValues.row.cotacao.id_cotacao;
        var url = "/gestao/operacional/almoxarifado/cotacoes/editarcotacao/" + id;
        window.open(url, '_blank');
    };



    const handleAtualizarValorUnitario = async (cellValues) => {
        setOpenAtualizando(true);

        const id_cotacao = cellValues.row.cotacao.id_cotacao;
        console.log("Novo valor: " + editingValue);
        const cadastro = {
            valor_unitario: editingValue,

        };
        const headers = {
            'Authorization': 'Bearer ' + Cookies.get("token")
        }
        const response = await api.put('/v1/protected/almoxarifado/cotacoes/atualizarvalorunitario/' + id_cotacao, cadastro,
            { headers: headers });

        const cadastro_salvo = response.data;
        if (cadastro_salvo) {
            recalcular();
        } else {
            alert("Erro de Conexão, tente novamente mais tarde");

        }
        setOpenAtualizando(false);


    };

    const [editingValue, setEditingValue] = useState(0);
    const handleInputChange = (event) => {
        setEditingValue(event.target.value);
    };



    const handleAtualizarCodigoFornecedor = async (cellValues) => {
        setOpenAtualizando(true);

        const id_cotacao = cellValues.row.cotacao.id_cotacao;
        console.log("Novo valor: " + codeEditingValue);
        const cadastro = {
            codigo_interno_fornecedor: codeEditingValue,

        };
        const headers = {
            'Authorization': 'Bearer ' + Cookies.get("token")
        }
        const response = await api.put('/v1/protected/almoxarifado/cotacoes/atualizarcodigofornecedor/' + id_cotacao, cadastro,
            { headers: headers });

        const cadastro_salvo = response.data;
        if (cadastro_salvo) {
        } else {
            alert("Erro de Conexão, tente novamente mais tarde");

        }
        setOpenAtualizando(false);

    };

    const [codeEditingValue, setCodeEditingValue] = useState('NAN');
    const handleInputCodeEditingChange = (event) => {
        setCodeEditingValue(event.target.value);
    };





    const handleAtualizarUnidadeFornecedor = async (cellValues) => {

        setOpenAtualizando(true);
        const id_cotacao = cellValues.row.cotacao.id_cotacao;
        console.log("Novo valor: " + unidadeEditingValue);
        const cadastro = {
            unidade: unidadeEditingValue,

        };
        const headers = {
            'Authorization': 'Bearer ' + Cookies.get("token")
        }
        const response = await api.put('/v1/protected/almoxarifado/cotacoes/atualizarunidade/' + id_cotacao, cadastro,
            { headers: headers });

        const cadastro_salvo = response.data;
        if (cadastro_salvo) {
        } else {
            alert("Erro de Conexão, tente novamente mais tarde");

        }
        setOpenAtualizando(false);

    };

    const [unidadeEditingValue, setUnidadeEditingValue] = useState('NAN');
    const handleInputUnidadeEditingChange = (event) => {
        setUnidadeEditingValue(event.target.value);
    };


    async function baixarArquivo(caminho) {
        try {
            const token = Cookies.get('token');
            const headers = {
                'Authorization': 'Bearer ' + token
            }

            const response = await api.post("/v1/protected/ordensdecompras/precotacao/baixaranexo", { caminho: encodeURIComponent(caminho) }, {
                headers: headers,
                responseType: 'arraybuffer',  // Indica que a resposta é um array de bytes
            });


            const extensao = caminho.split('.').pop().toLowerCase(); // Obtém a última parte do caminho após o último ponto e converte para minúsculas


            // Determina o tipo de mídia com base na extensão do arquivo
            let mediaType = 'application/octet-stream'; // Define um tipo padrão
            switch (extensao) {
                case 'pdf':
                    mediaType = 'application/pdf';
                    break;
                case 'doc':
                    mediaType = 'application/msword';
                    break;
                case 'docx':
                    mediaType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                    break;
                case 'xls':
                    mediaType = 'application/vnd.ms-excel';
                    break;
                case 'xlsx':
                    mediaType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    break;
                // Adicione outros tipos de arquivo conforme necessário
            }

            const blob = new Blob([response.data], { type: mediaType });

            // Cria uma URL do blob
            const blobUrl = URL.createObjectURL(blob);

            // Obtém o nome do arquivo a partir do caminho
            const nomeArquivo = caminho.split('/').pop(); // Obtém a última parte do caminho após a última barra

            // Cria um link <a> temporário
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = nomeArquivo; // Define o nome do arquivo a ser baixado
            link.click(); // Simula um clique no link para iniciar o download
        } catch (error) {
            // Trate os erros conforme necessário
            console.log("Erro ao baixar o arquivo: " + error);
        }
    }



    const [contextMenu, setContextMenu] = useState(null);
    const [insumoAVariar, setInsumoAVariar] = useState(null);


    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setContextMenu(null);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };




    const columnsDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 80,
            renderCell: (cellValues) => {




                return (
                    ordemCompra.status === 0 &&

                    <div>
                        <IconButton onClick={(event) => handleClickRemocao(event, cellValues)}>
                            <Delete />
                        </IconButton>
                        <IconButton onClick={(event) => handleClickEditarCotacao(event, cellValues)}>
                            <EditIcon />
                        </IconButton>
                    </div>
                )
            },
        },
        {
            headerName: 'ID',
            field: 'id_item_ordem_compra',
            id: 1,
            width: 80,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Cód Fornecedor',
            field: 'codigo_interno_fornecedor',
            id: 1,
            width: 100,
            headerClassName: 'cabecalho_azul',
            renderCell: (cellValues) => {

                const code = cellValues.row.cotacao?.codigo_interno_fornecedor || 'NAN';

                return (
                    <TextField
                        defaultValue={code}
                        onBlur={(event) => handleAtualizarCodigoFornecedor(cellValues)}
                        onFocus={() => setCodeEditingValue(code)}
                        onChange={handleInputCodeEditingChange}
                        variant="outlined"
                    />

                )

            },
        },
        {
            headerName: 'Insumo',
            field: 'cotacao',
            id: 2,
            width: 500,
            headerClassName: 'cabecalho_azul',
            renderCell: (cellValues) => {
                const insumo = cellValues.row.insumo;
                const cotacao = cellValues.row.cotacao;

                const handleContextMenu = (event) => {
                    console.log(JSON.stringify(insumo));
                    setInsumoAVariar(insumo);
                    event.preventDefault();
                    setContextMenu(
                        contextMenu === null
                            ? {
                                mouseX: event.clientX + 2,
                                mouseY: event.clientY - 6
                            }
                            : null
                    );
                };

                let textoComQuebraDeLinha = "Descrição Principal: " + insumo.descricao + "\n\n";

                if (insumo.variacoes && insumo.variacoes.length > 0) {
                    textoComQuebraDeLinha += insumo.variacoes
                        .filter(variacao => variacao.id_fornecedor !== cotacao.fornecedor.id_fornecedor)
                        .map((variacao, index) => (
                            `Fornecedor: ${variacao.nome_amigavel_fornecedor} Variação: ${variacao.descricao}${index !== insumo.variacoes.length - 1 ? '\n' : ''}`
                        )).join('');
                } else {
                    return (
                        <div onContextMenu={handleContextMenu}>
                            <span>{returnDescricaoInsumo(insumo)}</span>
                        </div>
                    );
                }

                return (
                    <Tooltip
                        title={
                            <div style={{ backgroundColor: 'white', color: 'black', size: 16, minHeight: '50px', minWidth: '400px', border: '2px solid black' }}>
                                <span>
                                    {textoComQuebraDeLinha.split('\n').map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </span>
                            </div>
                        }
                        placement="top"
                        arrow
                        style={{ backgroundColor: 'white' }}
                        sx={{ backgroundColor: 'white' }}
                    >
                        <div onContextMenu={handleContextMenu}>
                            <span>{returnDescricaoInsumo(insumo)}</span>
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            headerName: 'Quantidade',
            field: 'quantidade',
            id: 4,
            headerClassName: 'cabecalho_azul',
        },
        {
            headerName: 'Unidade',
            id: 5,
            field: 'unidade',
            headerClassName: 'cabecalho_azul',
            renderCell: (cellValues) => {

                const unidade = cellValues.row.cotacao?.unidade || 'NAN';

                return (
                    <TextField
                        defaultValue={unidade}
                        onBlur={(event) => handleAtualizarUnidadeFornecedor(cellValues)}
                        onFocus={() => setUnidadeEditingValue(unidade)}
                        onChange={handleInputUnidadeEditingChange}
                        variant="outlined"
                    />

                )

            },
        },
        {
            headerName: 'Valor Uni.',
            id: 6,
            width: 150,
            field: 'valor_unitario',
            headerClassName: 'cabecalho_azul',

            renderCell: (cellValues) => {

                const valor_unitario = cellValues.row.cotacao?.valor_unitario || 0;

                return (
                    <TextField
                        defaultValue={currencyFormatter.format(valor_unitario)}
                        onBlur={(event) => handleAtualizarValorUnitario(cellValues)}
                        onFocus={() => setEditingValue(valor_unitario)}
                        onChange={handleInputChange}
                        variant="outlined"
                    />

                )

            },

        },
        {
            headerName: 'Valor Total',
            id: 7,
            headerClassName: 'cabecalho_azul',
            field: 'valor_total',
            valueGetter: (params) => {
                var quantidade = params.row.quantidade; // Verifica se params.data está definido
                var valor_unitario = params.row.cotacao?.valor_unitario || 0;
                var total = quantidade * valor_unitario;
                return total;
            },
            valueFormatter: (params) => currencyFormatter.format(params.value),

        },
    ];







    function adicionarItem() {

        window.location.href = "/gestao/administracao/ordensdecompras/editar/" + idOrdemCompra + "/editarcotacao/" + idPreCotacao + "/inseriritemnacotacao";

    }






    function returnDescricaoInsumoSimplificada(insumo) {


        var texto =


            "Descrição: " + insumo.descricao;
        return texto;
    }


    const columnsItensDataGrid = [

        {
            headerName: 'Insumo',
            field: 'insumo',
            id: 2,
            width: 700,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                const insumo = params.row.insumo;
                let descricao = returnDescricaoInsumoSimplificada(insumo);
                descricao = descricao.replace(/<br\s*\/?>/gi, '\n');
                return <div style={{ whiteSpace: 'pre-line' }}>{descricao}</div>;
            },
        },


        {
            headerName: 'Quantidade',
            field: 'quantidade',
            id: 3,
            width: 80,
            headerClassName: 'cabecalho_azul',
        },

        {
            headerName: 'Observações',
            field: 'observacoes',
            id: 4,
            width: 200,
            headerClassName: 'cabecalho_azul',
        },

    ];

    const [arrowRef, setArrowRef] = useState(null);

    const handleArrowRef = (node) => {
        setArrowRef(node);
    };




    const CustomDialog = ({ insumo }) => {
        const [descricao, setDescricao] = useState('');



        const handleDescricaoChange = (event) => {
            setDescricao(event.target.value);
        };

        async function salvarVariacao() {


            console.log("id do insumo a variar: " + insumo.id_insumo);

            var nome = preCotacao.fornecedor.tipo_fornecedor === 0 ? (preCotacao.fornecedor.nome + " " + preCotacao.fornecedor.sobrenome) :
                (preCotacao.fornecedor.nome_fantasia);
            //  return null;
            try {

                console.log("cadastrar chamado");
                const cadastro_variacao = {

                    descricao: descricao,
                    id_fornecedor: preCotacao.fornecedor.id_fornecedor,
                    nome_amigavel_fornecedor: nome,


                }



                const headers = {
                    'Authorization': 'Bearer ' + Cookies.get("token")
                }

                const response = await api.post('/v1/protected/almoxarifado/insumos/' + insumo.id_insumo + "/cadastrarvariacao", cadastro_variacao,
                    { headers: headers });

                const cadastro_salvo = response.data;
                if (cadastro_salvo) {
                    alert("Variação Cadastrada!");
                    closeModal();

                    listarInformacoes();


                } else {
                    alert("Erro de Conexão, tente novamente mais tarde");
                }


            } catch (_err) {
                console.log("erro ao cadastrar: " + _err);
                alert("Erro de Conexão, tente novamente mais tarde");

            }



        }

        return (

            <Dialog open={isOpen} onClose={closeModal} PaperProps={{ style: { maxWidth: '600px', minWidth: '300px' } }}>
                <DialogTitle>Nova Variação de Insumo</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} style={{ paddingTop: 20 }}>
                            <TextField
                                label="Descrição"
                                variant="outlined"
                                fullWidth
                                value={descricao}
                                onChange={handleDescricaoChange}
                                multiline
                                minRows={2}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={salvarVariacao} variant="contained" color="primary">
                                Criar Variação
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    };


    const handleAdicionarMetodoPagamento = (event, newValue) => {
        // Verifica se o valor selecionado já está na lista
        const valoresSelecionados = Array.isArray(newValue) ? newValue : [newValue];
        const novosSolicitantes = valoresSelecionados.filter(
            (opcao) => !metodosPagamentosSelecionados.some((selecionado) => selecionado.id_metodo_pagamento === opcao.id_metodo_pagamento)
        );

        setMetodosPagamentosSelecionados([...metodosPagamentosSelecionados, ...novosSolicitantes]);
    };


    const handleRemoverMetodoPagamento = (event, metodoRemovido) => {
        const novosMetodos = metodosPagamentosSelecionados.filter(
            (metodo) => metodo.id_metodo_pagamento !== metodoRemovido.id_metodo_pagamento
        );
        setMetodosPagamentosSelecionados(novosMetodos);
    };

    function returnDescricaoMetodoPagamento(metodo) {
        var texto = "ID_METODO: " + metodo.id_metodo_pagamento + "\nNome: " + metodo.nome +
            "\nCodigo: " + metodo.codigo + "\nSigla: " + metodo.sigla + "\nDescrição: " + metodo.descricao;
        return texto;
    }




    const handleAdicionarCondicaoPagamento = (event, newValue) => {
        // Verifica se o valor selecionado já está na lista
        setCondicaoPagamentoSelecionada(newValue); 
    };

   
    function returnDescricaoCodicaoPagamento(condicao) {
        var texto = "ID_CONDICAO: " + condicao.id_condicao_pagamento + "\nNome: " + condicao.nome +
            "\nDescrição: " + condicao.descricao +
            "\nNúmero de Parcelas: " + condicao.numero_parcelas + " parcelas" +
            "\nTipo Intervalo de Parcelas: " + (condicao.tipo_intervalo === 0 ? " Fixo" : "Variavel") + 
            "\nIntervalo de Parcelas:" +  (condicao.tipo_intervalo === 0 ? ("parcelas a cada " + condicao.intervalo_parcelas + " dias")  : (condicao.intervalo_variavel + " dias")) +
            "\nPrimeira parcela para: " + (condicao.intervalo_primeira_parcela === 0 ? " Ato da Compra" : "Em " + condicao.intervalo_primeira_parcela + " dias") +
            "\nTipo Divisão de Valores: " + (condicao.divisao_variavel === 0 ? "Parcelas com valores iguais" : condicao.divisao_variavel);
            return texto;
    }

    return (
        <div>
            <NavegadorGestao
                Gestao={true}
                OrdensCompras={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5, padding: 10 }} >
                {loading
                    ?
                    <div></div>

                    :

                    <React.Fragment >
                        <Grid
                            style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={12}
                                style={{ marginTop: 5 }}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    align="flex-start"
                                    item xs={12}
                                    style={{ padding: 5 }}
                                >

                                    <a href="/gestao/administracao/ordensdecompras/listar" style={{ fontSize: 14, color: 'black', marginRight: '5px' }}>{"Ordens de Compras"}</a>
                                    <span style={{ fontSize: 12 }}>-</span>
                                    <a href={"/gestao/administracao/ordensdecompras/editar/" + idOrdemCompra} style={{ fontSize: 14, color: 'black', marginLeft: '5px' }}>{"Ordem de Compra nº " + idOrdemCompra}</a>
                                </Grid>






                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ padding: 5 }}
                                >

{ ordemCompra.ordem_servico ?
<Grid
container
direction="row"
justifyContent="flex-start"
align="flex-start"
item xs={3}
style={{ marginTop: 5 }}
>
<Tooltip
    title={
        <div
            style={{ backgroundColor: 'white', minHeight: '400px', minWidth: '1000px', border: '2px solid black' }}
        >

            <Grid
                container
                direction="row"
                item xs={12}
                alignItems="center"
                justifyContent="center"
            >
                {
                    ordemCompra.ordem_servico.itens_pre_requerimento &&
                    <>
                        <DataGrid
                            localeText={ptBR.localeText}
                            rows={ordemCompra.ordem_servico.itens_pre_requerimento}
                            getRowId={(row) => row.id_item_requerimento}
                            columns={columnsItensDataGrid}
                            disableColumnResize={false}
                            rowHeight={40}
                            sortModel={[
                                {
                                    field: 'id_item_requerimento', // Campo pelo qual deseja ordenar
                                    sort: 'asc', // 'asc' para ascendente, 'desc' para descendente
                                },
                            ]}
                        />

                    </>
                }



            </Grid>
        </div>
    }
    placement="right"
    arrow
    style={{ backgroundColor: 'white' }}
    sx={{ backgroundColor: 'white' }}
>
    <IconButton aria-label="Requerimento de Itens">
        <HelpOutlineIcon color='blue' />
    </IconButton>
</Tooltip>
</Grid>
:
<Grid
container
direction="row"
justifyContent="flex-start"
align="flex-start"
item xs={3}
style={{ marginTop: 5 }}
>
<Tooltip
    title={
        <div
            style={{ backgroundColor: 'white', minHeight: '400px', minWidth: '1000px', border: '2px solid black' }}
        >

            <Grid
                container
                direction="row"
                item xs={12}
                alignItems="center"
                justifyContent="center"
            >
                {
                    ordemCompra.itens_pre_requerimento &&
                    <>
                        <DataGrid
                            localeText={ptBR.localeText}
                            rows={ordemCompra.itens_pre_requerimento}
                            getRowId={(row) => row.id_item_requerimento}
                            columns={columnsItensDataGrid}
                            disableColumnResize={false}
                            rowHeight={40}
                            sortModel={[
                                {
                                    field: 'id_item_requerimento', // Campo pelo qual deseja ordenar
                                    sort: 'asc', // 'asc' para ascendente, 'desc' para descendente
                                },
                            ]}
                        />

                    </>
                }



            </Grid>
        </div>
    }
    placement="right"
    arrow
    style={{ backgroundColor: 'white' }}
    sx={{ backgroundColor: 'white' }}
>
    <IconButton aria-label="Requerimento de Itens">
        <HelpOutlineIcon color='blue' />
    </IconButton>
</Tooltip>
</Grid>

}

                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        align="flex-end"
                                        item xs={8}
                                        style={{ marginTop: 5 }}
                                    >

                                        {preCotacao.caminho_arquivo_fornecedor ? (
                                            <div>
                                                <div
                                                    onClick={() => {
                                                        baixarArquivo(preCotacao.caminho_arquivo_fornecedor);
                                                    }}
                                                    style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '50px', height: '50px' }}
                                                >
                                                    {/* Verifica a extensão do arquivo e exibe a miniatura ou o ícone correspondente */}
                                                    {preCotacao.caminho_arquivo_fornecedor.toLowerCase().endsWith('.pdf') ? (
                                                        <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf} />
                                                    ) : (
                                                        <img alt={'office'} style={{ textAlign: 'center' }} src={office} />
                                                    )}
                                                    <Typography align="center" variant="h7">
                                                        Arquivo do Fornecedor
                                                    </Typography>
                                                </div>
                                                <SwapHorizIcon {...getRootArquivoProps1()} />
                                            </div>
                                        ) : (
                                            <div {...getRootArquivoProps1()} style={dropzoneStyles}>
                                                <input {...getInputArquivoProps1()} />
                                                <Typography align="center" variant="h7">
                                                    Anexo
                                                </Typography>
                                            </div>
                                        )}

                                    </Grid>



                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        align="center"
                                        item xs={6}
                                    >

                                        <Autocomplete
                                            id="select-fornecedor"
                                            options={[]}
                                            disableCloseOnSelect
                                            disabled
                                            getOptionLabel={(fornecedor) => returnDescricaoFornecedor(fornecedor).replace(/<br \/>/g, '\n')}
                                            value={fornecedorSelecionado}
                                            getOptionSelected={(option, value) => true}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((fornecedor) => {
                                                    const cotacaoText = returnDescricaoFornecedor(fornecedor).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Fornecedor " multiline maxRows={6} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoFornecedor(option) }} />
                                            )}
                                        />
                                    </Grid>

                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        align="center"
                                        item xs={3}
                                    >
                                        <Autocomplete
                                            id="select-vendedor"
                                            options={fornecedorSelecionado.contatos}
                                            disableCloseOnSelect
                                            getOptionLabel={(vendedor) => returnDescricaoContato(vendedor).replace(/<br \/>/g, '\n')}
                                            value={vendedorSelecionado}
                                            onChange={(e, v) => setVendedorSelecionado(v)}
                                            getOptionSelected={(option, value) => true}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((fornecedor) => {
                                                    const cotacaoText = returnDescricaoContato(fornecedor).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Vendedor " multiline maxRows={6} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoContato(option) }} />
                                            )}
                                        />
                                    </Grid>

                                </Grid>





                                {ordemCompra.status === 0 &&
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="flex-end"
                                        item xs={12}
                                    >
                                        <Button style={{ marginTop: 5 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={(ev) => adicionarItem()}
                                        > Adicionar Item  </Button>
                                    </Grid>
                                }


                                <Grid
                                    style={{ paddingTop: 5 }}
                                    container
                                    direction="row"
                                    item xs={12}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <div     >
                                        <DataGrid
                                            localeText={ptBR.localeText}
                                            pagination
                                            checkboxSelection={false}
                                            rows={itens}
                                            getRowId={(row) => row.id_item_ordem_compra}
                                            disableColumnResize={false}
                                            onCellClick={handleCellClick}
                                            onRowClick={handleRowClick}
                                            components={{
                                                Toolbar: CustomToolbar,
                                            }}
                                            columns={columnsDataGrid.map(column => ({
                                                ...column,
                                                disableColumnMenu: true // Desabilita o menu de opções da coluna
                                            }))}
                                            sortModel={[
                                                {
                                                    field: 'id_item_ordem_compra', // Campo pelo qual deseja ordenar
                                                    sort: 'asc', // 'asc' para ascendente, 'desc' para descendente
                                                },
                                            ]}


                                        />

                                    </div>


                                </Grid>






                                <Grid item xs={12}
                                    style={{ padding: 10 }}
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"

                                >

                                    <Grid item xs={6}
                                        style={{ padding: 10 }}

                                    >
                                        <TextField
                                            variant="standard"
                                            name="valorFrete"
                                            label="Valor Do Frete"
                                            required
                                            id="valorFrete"
                                            value={valorFrete}
                                            onChange={(e) => setValorFrete(e.target.value)}
                                            fullWidth
                                            rows={1}
                                        />
                                    </Grid>
                                </Grid>





                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    item xs={12}
                                >

                                    <Grid
                                        item xs={12}
                                    >
                                        <Typography variant="h6">
                                            {"Valor Total dos Itens: " + currencyFormatter.format(parametrosValores.valorTotalItens)}
                                        </Typography>
                                        <Typography variant="h6">
                                            {"Valor Total IPI: " + currencyFormatter.format(parametrosValores.valorTotalIpi)}
                                        </Typography>
                                        <Typography variant="h6">
                                            {"Valor Total ICMS: " + currencyFormatter.format(parametrosValores.valorTotalIcms)}
                                        </Typography>
                                        <Typography variant="h6">
                                            {"Valor do Frete: " + currencyFormatter.format(valorFrete)}
                                        </Typography>
                                        <Typography variant="h6" style={{ paddingTop: 20 }}>
                                            {"Valor Final: " + currencyFormatter.format(parametrosValores.valorTotal)}
                                        </Typography>

                                    </Grid>


                                </Grid>




                                <Grid item xs={12}
                                    style={{ padding: 5 }}
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"

                                >

                                    <Grid item xs={6}
                                        style={{ padding: 5 }}

                                    >
                                        <TextField
                                            variant="standard"
                                            name="diasPrevistaEntrega"
                                            label="Prazo da Entrega"
                                            id="diasPrevistaEntrega"
                                            value={diasPrevistaEntrega}
                                            onChange={(e) => setDiasPrevistaEntrega(e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>


                                <Grid item xs={12}
                                    style={{ padding: 5 }}
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"

                                >

                                    <Grid item xs={6}
                                        style={{ padding: 5 }}

                                    >
                                        <TextField
                                            variant="standard"
                                            name="dataEntrega"
                                            label="Data da Entrega"
                                            id="dataEntrega"
                                            value={dataEntrega}
                                            onChange={(e) => setDataEntrega(e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}
                                    style={{ padding: 5 }}
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"

                                >

                                    <Grid item xs={6}
                                        style={{ padding: 5 }}

                                    >
                                        <TextField
                                            variant="standard"
                                            name="observacoes"
                                            label="Observações"
                                            required
                                            id="observacoes"
                                            value={observacoes}
                                            onChange={(e) => setObservacoes(e.target.value)}
                                            fullWidth
                                            multiline
                                            minRows={3}
                                        />
                                    </Grid>
                                </Grid>



                                <Typography variant="h6" style={{ marginTop: 5 }}>
                                    Atributos Financeiros
                                </Typography>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ marginTop: 5 }}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        align="center"
                                        item xs={6}
                                    >

                                        <Autocomplete
                                            id="select-metodos-pagamento"
                                            options={metodosPagamento}
                                            disableCloseOnSelect
                                            multiple
                                            fullWidth
                                            getOptionLabel={(metodo) => returnDescricaoMetodoPagamento(metodo).replace(/<br \/>/g, '\n')}
                                            value={metodosPagamentosSelecionados}
                                            onChange={handleAdicionarMetodoPagamento}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((solicitante) => {
                                                    const cotacaoText = returnDescricaoMetodoPagamento(solicitante).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Metodos de Pagamento " multiline rows={2} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoMetodoPagamento(option).replace(/\n/g, '<br>') }} />
                                            )}
                                            renderTags={(value, getTagProps) =>
                                                value.map((metodo, index) => (
                                                    <Chip
                                                        label={returnDescricaoMetodoPagamento(metodo).replace(/<br \/>/g, '\n')}
                                                        {...getTagProps({ index })}
                                                        onDelete={(event) => handleRemoverMetodoPagamento(event, metodo)}
                                                    />
                                                ))
                                            }
                                        />
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    align="center"
                                    item xs={12}
                                    style={{ marginTop: 5 }}
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        align="center"
                                        item xs={6}
                                    >

                                        <Autocomplete
                                            id="select-condicao_pagamento"
                                            options={condicoesPagamento}
                                            disableCloseOnSelect
                                            fullWidth
                                            getOptionLabel={(condicao) => returnDescricaoCodicaoPagamento(condicao).replace(/<br \/>/g, '\n')}
                                            value={condicaoPagamentoSelecionada}
                                            onChange={handleAdicionarCondicaoPagamento}
                                            filterOptions={(options, { inputValue }) => {
                                                const inputKeywords = inputValue.toLowerCase().split(" ");
                                                return options.filter((solicitante) => {
                                                    const cotacaoText = returnDescricaoCodicaoPagamento(solicitante).toLowerCase();
                                                    return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label=" Condições de Pagamento " multiline minRows={5} />
                                            )}
                                            renderOption={(option) => (
                                                <div dangerouslySetInnerHTML={{ __html: returnDescricaoCodicaoPagamento(option).replace(/\n/g, '<br>') }} />
                                            )}
                                            renderTags={(value, getTagProps) =>
                                                value.map((metodo, index) => (
                                                  <Paper key={index} variant="outlined" style={{ margin: '4px', padding: '4px', maxWidth: '100%', whiteSpace: 'pre-line', position: 'relative' }}>
                                                    <Typography>
                                                      {returnDescricaoCodicaoPagamento(metodo).replace(/<br \/>/g, '\n')}
                                                    </Typography>
                                                   
                                                  </Paper>
                                                ))
                                              }
                                        />
                                    </Grid>
                                </Grid>


                                {
                                    ordemCompra.status === 0 &&
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        item xs={10}
                                    >
                                        <Button style={{ marginTop: 50 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={atualizar}
                                        > Atualizar </Button>
                                    </Grid>
                                }


                            </Grid>

                        </Grid>
                    </React.Fragment>
                }

            </div>

            <Snackbar
                open={openAtualizando}
                autoHideDuration={1500}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <MuiAlert elevation={6} variant="filled"  >
                    Atualizando...
                </MuiAlert>
            </Snackbar>
            <Menu
                open={contextMenu != null}
                onClose={() => setContextMenu(null)}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu != null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <MenuItem onClick={(e) => openModal()}>Definir Variação</MenuItem>
            </Menu>


            <CustomDialog insumo={insumoAVariar} />


        </div >
    );
}


