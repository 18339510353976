import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';

import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import api from '../../../../services/api';
import MenuAdmin from '../../components/menu';
import NavBarAdmin from "../../../../components/NavBarAdmin";

import Rodape from '../../../../components/Rodape';
import { Button } from "@material-ui/core";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { styled } from '@material-ui/styles';


const drawerWidth = 240;

const ColorButton = styled(Button)(({ theme }) => ({
    color: 'green',
    backgroundColor: 'white',
    borderColor: 'green',
    '&:hover': {
        backgroundColor: 'green',
        color: 'white',
    },
}));

const ColorButtonOrange = styled(Button)(({ theme }) => ({
    color: 'orange',
    backgroundColor: 'white',
    borderColor: 'orange',
    '&:hover': {
        backgroundColor: 'orange',
        color: 'white',
    },
}));

const ColorButtonPurple = styled(Button)(({ theme }) => ({
    color: 'purple',
    backgroundColor: 'white',
    borderColor: 'purple',
    '&:hover': {
        backgroundColor: 'purple',
        color: 'white',
    },
}));


const ColorButtonBlack = styled(Button)(({ theme }) => ({
    color: 'black',
    backgroundColor: 'white',
    borderColor: 'black',
    '&:hover': {
        backgroundColor: 'black',
        color: 'white',
    },
}));







const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .cabecalho_transparente': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            color: 'rgba(0, 0, 0, 0)',
        },
        '& .cabecalho_azul': {
            backgroundColor: 'rgba(0, 0, 255, 1)',
            color: 'white',
        },
        '& .cabecalho_verde': {
            backgroundColor: 'rgba(0, 100, 0, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_claro': {
            backgroundColor: 'rgba(107,142,35, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_claro': {
            backgroundColor: 'rgba(184,134,11, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_cyan': {
            backgroundColor: 'rgba(0,139,139, 1)',
            color: 'white',
        },
        '& .cabecalho_verde_dark_sea': {
            backgroundColor: 'rgba(60,179,113, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_escuro': {
            backgroundColor: 'rgba(139,69,19, 1)',
            color: 'white',
        },
        '& .cabecalho_marrom_chocolate': {
            backgroundColor: 'rgba(210,105,30, 1)',
            color: 'white',
        },
        '& .cabecalho_darkslate': {
            backgroundColor: 'rgba(47,79,79, 1)',
            color: 'white',
        },
        '& .super-app.negative': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.positive': {
            backgroundColor: '#d47483',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.neutro': {
            backgroundColor: '#363636',
            color: 'white',
            fontWeight: '600',
        },
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    table: {
        minWidth: 650,
    },
}));


function novoModuloSonoro() {
    window.location.href = "/cadastrarModuloSonoro"

}

export default function ModulosSonoros() {



    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [modulosSonoros, setModulosSonoros] = useState([]);
  
    

    async function listarModulosSonoros() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            await api.get("v1/protected/modulosonoro/listartodas", {
                headers: headers
            }).then(function (response) {
                setModulosSonoros(response.data)

                console.log(" modulos sonoros: " + response);
                setLoading(false);


            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar modulos sonoros: " + _err)

        }

    }



    useEffect(() => {




        listarModulosSonoros();


    }, []);


    function CollapsibleTable() {
        return (
            <TableContainer component={Paper} >
                <Table aria-label="collapsible table">
                    <TableHead>

                        <TableRow  >
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>ID</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>Status</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>Marca</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>modelo</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>Data Fabricação</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>Código</TableCell>
                            <TableCell style={{ backgroundColor: 'black', color: 'white', position: "sticky", top: 0 }} align="center" colSpan={1}>Fornecedor</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {modulosSonoros.map((modulosonoro) => (
                            <Row key={modulosonoro.id_modulo_sonoro} row={modulosonoro} />
                        ))}
                    </TableBody>





                </Table>
            </TableContainer>
        );
    }



    async function excluir(id) {

        console.log("Funcao deletar chamada, id: " + id);
        if (window.confirm("Excluir o Módulo Sonoro Selecionado?")) {
            var result = await api.delete("/v1/protected/modulosonoro/excluir/" + id);
            console.log("resultado da exclusao: " + result.status);
            if (result.status === 204) {

                window.location.href = "/modulosSonoros";

            } else {
                alert("Erro ao Excluir! Tente novamente");
            }
        }
    }

  

    function acessarMonitor(url_monitor){
        window.open(url_monitor);
        //window.location.href = ;
    }

    function acessoRemoto(url_vnc_web){
        window.open(url_vnc_web);
        //window.location.href = ;
    }

    function acessoSshRemoto(url_ssh_web){
        window.open(url_ssh_web);
        //window.location.href = ;
    }



    

    function Row(props) {
        const { row } = props;


        function returnCorStatus(status) {
            if (status === 0) {
                //livre
                return "yellow";
            } else if (status === 1) {
                return "red";
            } else if (status === 2) {
                return "green";
            } else {
                return "black";
            }
        }

        function returnStatus(status) {
            if (status === 0) {
                //livre
                return "LIVRE";
            } else if (status === 1) {
                return "EM MANUTENÇÃO";
            } else if (status === 2) {
                return "INSTALADO";
            } else {
                return "INUTILIZADO";
            }
        }

        function returnDescricaoFornecedor(fornecedor) {
            var texto = "  Cliente_ID: " + fornecedor.id_fornecedor + "\n CPF/CNPJ: " + (fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj) +
                "\n Nome/Nome Fantasia: " + (fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) : fornecedor.nome_fantasia) +
                "\n Endereço: " + (fornecedor.tipo_endereco === 0 ? "Normal " : fornecedor.tipo_endereco === 1 ? "Edifício/Prédio " : "Condomínio ") + fornecedor.logradouro + ", nº " + fornecedor.numero + ", Bairro: " + fornecedor.bairro + ", " + fornecedor.cidade + "-" + fornecedor.estado + " CEP: " + fornecedor.cep;
            return texto;
        }




        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>



                    <TableCell colSpan={1} align="right">{row.id_modulo_sonoro}</TableCell>
                    <TableCell colSpan={1} align="right" style={{ backgroundColor: returnCorStatus(row.status) }} > {returnStatus(row.status)} </TableCell>
                    <TableCell colSpan={1} align="right">{row.marca}</TableCell>
                    <TableCell colSpan={1} align="right">{row.modelo}</TableCell>
                    <TableCell colSpan={1} align="right">{row.data_fabricacao}</TableCell>
                    <TableCell colSpan={1} align="right">{row.codigo}</TableCell>
                    <TableCell colSpan={1} align="right">{row.fornecedor != null ? returnDescricaoFornecedor(row.fornecedor) : "NÃO INSTALADA"}</TableCell>

                    <TableCell colSpan={1} align="center">
                        <Grid item xs={12} spacing={2}>
                            <ButtonGroup >
                                <Button color="primary" href={"/alterarmodulosonoro/" + row.id_modulo_sonoro}>Atualizar</Button>
                                <Button color="secondary" onClick={() => excluir(row.id_modulo_sonoro)} >Excluir</Button>

                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={12} spacing={2}>
                            <ButtonGroup >
                                <ColorButton variant="outlined" onClick={() => acessarMonitor(row.url_monitor)} >Acessar Monitor</ColorButton>
                                <ColorButtonOrange variant="outlined" onClick={() => acessoRemoto(row.url_vnc_web)} >Acesso Remoto</ColorButtonOrange>
                                <ColorButtonPurple variant="outlined" onClick={() => acessoSshRemoto(row.url_ssh_web)} >SSH Remoto</ColorButtonPurple>


                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={12} spacing={2}>
                            <ButtonGroup >
                                <ColorButtonBlack color="primary" href={"/gerenciarmodulosonoro/" + row.id_modulo_sonoro}>Gerenciar</ColorButtonBlack>

                            </ButtonGroup>
                        </Grid>
                    </TableCell>

                </TableRow>


            </React.Fragment>
        );
    }

    


    return (
        <div>

            <NavBarAdmin />
            <div className={classes.root} style={{ backgroundColor: '#DCDCDC' }}>
                <MenuAdmin titulo={"Módulos Sonoros"} />

                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />

                    <div style={{ padding: 10, width: '100%', height: '70%' }}>
                        {loading ?
                            <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                            </Skeleton>
                            :

                            <div>
                                <CollapsibleTable></CollapsibleTable>

                            </div>
                        }
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            item xs={12}
                        >
                            <Button style={{ marginTop: 20 }}
                                variant="contained"
                                color="primary"
                                onClick={novoModuloSonoro}
                            >
                                Novo Módulo Sonoro
                            </Button>
                        </Grid>

                    </div>

                </main>
            </div>

           
            <div >
                <Rodape />
            </div>
        </div>
    );
}



