import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import { Card, CardContent } from '@mui/material';
import { Email, EmailOutlined, Visibility, VisibilityOff, Message, MessageOutlined } from '@mui/icons-material';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";

import { Button } from "@material-ui/core";


import {
    DataGrid, ptBR, GridLinkOperator,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';




function novaNotificacao() {
    window.location.href = "/gestao/operacional/notificacoes/cadastrar"

}

export default function Notificacoes() {



    const [loading, setLoading] = useState(true);
    const [notificacoes, setNotificacoes] = useState([]);






    async function listarObjetos() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/notificacoes/listartodos", {
                headers: headers
            }).then(function (response) {
                setNotificacoes(response.data)

                setLoading(false);

            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar Notificações: " + _err)

        }

    }


    useEffect(() => {




        listarObjetos();


    }, []);




    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }


    async function visualizar(caminho){

        try {
            const token = Cookies.get('token');
            const headers = {
                'Authorization': 'Bearer ' + token
            }

            console.log("Caminho: "  + caminho);
            const cadastro = {

                dado1: caminho

            };
            const response = await api.post("/v1/protected/notificacoes/baixarPdf", cadastro,{
                headers: headers,
                responseType: 'arraybuffer',  // Indica que a resposta é um array de bytes
            });

            // Cria um blob com os dados recebidos
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Cria uma URL do blob
            const blobUrl = URL.createObjectURL(blob);

            // Abre uma nova guia com o arquivo PDF
            window.open(blobUrl, '_blank');
        } catch (error) {
            // Trate os erros conforme necessário
            console.log("Erro ao baixar o arquivo: " + error);
        }
    }

    const handleClickVizualizar = (event, cellValues) => {

        visualizar( cellValues.row.caminho_arquivo_original);
    };


    function returnDescricaoInstalacao(instalacao) {
        var texto = "ID_Integrador: " + instalacao.identificador_integrador + "\n Instalacao_ID: " + instalacao.id_instalacao + "\n CPF/CNPJ: " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            "\n Nome/Nome Fantasia: " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

        return texto;
    }


    const columnsDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 100,
            renderCell: (cellValues) => {


                return (

                    <IconButton
                        color="primary"
                        onClick={(event) => handleClickVizualizar(event, cellValues)}
                    >
                        <VisibilityIcon />
                    </IconButton>

                );

            },
        },
        {
            headerName: 'ID',
            field: 'id_notificacao',
            id: 1,
            width: 50,
            headerClassName: 'cabecalho_azul',
        },
        {
            id: 4,
            field: 'informacoes', // Adicionei um campo único aqui
            headerName: 'Informações',
            minWidth: 50,
            headerClassName: 'cabecalho_azul',
            renderCell: (cellValues) => {

                const envios = cellValues.row.registros_envio;


                return (
                    <Tooltip
                        title={
                            <div style={{ backgroundColor: 'white', padding: 20, color: 'black', size: 16, minHeight: '50px', minWidth: '400px', border: '2px solid black' }}>

                            
                                {envios.map((envio, index) => (
                                    <Card key={index} style={{ marginBottom: '10px', borderRadius: '8px', border: '1px solid gray' }}>
                                        <CardContent>
                                            <Typography variant="body2">
                                                <strong>Destinatário:</strong> {envio.vendedor.nome_contato}
                                            </Typography>
                                            <Typography variant="body2">
                                                <strong>Plataforma de Envio:</strong> {envio.plataforma_envio === 0 ? 'WhatsApp' : 'Email'}
                                            </Typography>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <strong>Enviado:</strong> {envio.enviado === 1 ? <Email /> : <EmailOutlined />}
                                                </Typography>
                                                {envio.enviado === 1 && (
                                                    <Typography variant="body2" style={{ marginLeft: '8px' }}>
                                                        {moment(envio.data_hora_envio).format('DD/MM/YYYY HH:mm')}
                                                    </Typography>
                                                )}
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <strong>Visualizado:</strong> {envio.vizualizado === 1 ? <Visibility /> : <VisibilityOff />}
                                                </Typography>
                                                {envio.vizualizado === 1 && (
                                                    <Typography variant="body2" style={{ marginLeft: '8px' }}>
                                                        {moment(envio.data_hora_vizualizado).format('DD/MM/YYYY HH:mm')}
                                                    </Typography>
                                                )}
                                            </div>
                                        </CardContent>
                                    </Card>
                                ))}
                            </div>
                        }
                        placement="top"
                        arrow
                        style={{ backgroundColor: 'white' }}
                        sx={{ backgroundColor: 'white' }}
                    >
                        <span>Informações</span>
                    </Tooltip>

                );
            },

        },


        {
            headerName: 'Tipo da Ocorrência',
            field: 'tipo_ocorrencia',
            id: 3,
            width: 300,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                const tipo_ocorrencia = params.row.tipo_ocorrencia;

                return (
                    <Typography
                        variant="h8"
                    >
                        {tipo_ocorrencia.nome}
                    </Typography>
                );
            },
        },
        {
            headerName: 'Data e Hora do Registro',
            field: 'data_hora_criacao',
            id: 43,
            width: 200,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                const data_hora_criacao = params.row.data_hora_criacao;

                return (
                    <Typography
                        variant="h8"
                    >
                        {data_hora_criacao}
                    </Typography>
                );
            },
        },
        {
            headerName: 'Data e Hora da Ocorrência',
            field: 'data_hora_ocorrencia',
            id: 5,
            width: 200,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                var data_ocorrencia = params.row.data_ocorrencia;
                var hora_ocorrencia = params.row.hora_ocorrencia;

                return (
                    <Typography
                        variant="h8"
                    >
                        {data_ocorrencia + " " + hora_ocorrencia}
                    </Typography>
                );
            },
        },
        {
            headerName: 'Instalação',
            field: 'instalacao',
            id: 6,
            width: 300,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                const instalacao = params.row.instalacao;

                // Divida o texto em linhas usando '\n' como delimitador
                const linhas = returnDescricaoInstalacao(instalacao).split('\n');

                // Mapeie as linhas e adicione uma tag <br /> depois de cada uma, exceto na última linha
                const elementosLinhas = linhas.map((linha, index) => (
                    <React.Fragment key={index}>
                        {linha}
                        {index < linhas.length - 1 && <br />}
                    </React.Fragment>
                ));

                return (
                    <Typography variant="h8">
                        {elementosLinhas}
                    </Typography>
                );
            },
        },

        {
            headerName: 'Tipo Registro',
            field: 'tipo_registro',
            id: 7,
            width: 150,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                const tipo_registro = params.row.tipo_registro;

                let text = "";
                if (tipo_registro === 0) {
                    text = "Prevenção";
                }
                return (
                    <Typography
                        variant="h8"
                    >
                        {text}
                    </Typography>
                );
            },
        },

        {
            headerName: 'Classificação',
            field: 'classificacao',
            id: 8,
            width: 150,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                const classificacao = params.row.classificacao;

                let text = "";
                if (classificacao === 0) {
                    text = "Positiva - Automatico";
                }
                return (
                    <Typography
                        variant="h8"
                    >
                        {text}
                    </Typography>
                );
            },
        },

        {
            headerName: 'Forma de Conhecimento',
            field: 'forma_conhecimento',
            id: 9,
            width: 150,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                const forma_conhecimento = params.row.forma_conhecimento;

                let text = "";
                if (forma_conhecimento === 0) {
                    text = "Registro";
                }
                return (
                    <Typography
                        variant="h8"
                    >
                        {text}
                    </Typography>
                );
            },
        },

        {
            field: 'usuario_criador',
            id: 10,
            headerName: 'Usuário Criador',
            minWidth: '300',
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                const usuario_criador = params.row.usuario_criador;

                let texto = "ID: " + usuario_criador.id_usuario +
                    "\nNome: " + (usuario_criador.nome_amigavel);


                return (
                    <Typography
                        variant="h8"
                    >
                        {texto}
                    </Typography>
                );
            },

        },


    ];




    return (
        <div>

            <NavegadorGestao
                Operacional={true}
                Notificacoes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <div style={{ padding: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novaNotificacao}
                    >
                        Nova Notificação
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div style={{ height: '100%', width: '100%' }}>
                        <>
                            <DataGrid
                                localeText={ptBR.localeText}
                                pagination
                                rows={notificacoes}
                                pageSize={20}
                                rowHeight={40}
                                getRowId={(row) => row.id_notificacao}
                                columns={columnsDataGrid}
                                disableColumnResize={false}
                                onCellClick={handleCellClick}
                                onRowClick={handleRowClick}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            />

                        </>
                    </div>
                }


            </div>


        </div>
    );
}



