
import React, { useState, useEffect, useCallback } from 'react';
import Rodape from '../../components/Rodape';
import Navegador from '../../components/NavBar';
import capa_sobre from '../../assets/imgs/capa_sobre.jpg';
import capa_banner_sobre from '../../assets/imgs/capa_banner_sobre.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from '@material-ui/core/Grid';
import visao from '../../assets/imgs/visao.png';
import missao from '../../assets/imgs/missao.png';
import filosofia from '../../assets/imgs/filosofia.png';
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import capa1mobile from '../../assets/imgs/capa1mobile.png';
import area_atuacao from '../../assets/imgs/area_atuacao.png';
import Typography from '@material-ui/core/Typography';
import { useDropzone } from 'react-dropzone';
import api from '../../services/api';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import './styles.scss';

import globalConfig from '../../configs/globalConfigs';
import axios from 'axios';
import reCAPTCHA from "react-google-recaptcha"


import miniatura_pdf from '../../assets/imgs/miniatura_pdf.png';


const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};

const AutenticarDoc = () => {




    const [width, setWidth] = useState(0);


    function checkDimenssoes() {


        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;



        setWidth(largura)

    }

    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });



    useEffect(() => {



        checkDimenssoes();



    }, []);

    const [captchaVerified, setCaptchaVerified] = useState(false);

    const handleCaptchaVerify = (response) => {
        // Chamado quando o reCAPTCHA é verificado com sucesso
        setCaptchaVerified(true);
    };

    const [arquivoSelecionado1, setArquivoSelecionado1] = useState(null);


    const onDropArquivo1 = useCallback((acceptedFiles) => {
        // Valida o tipo de arquivo
        if (!acceptedFiles[0].type.includes('application/pdf')) {
            alert('Apenas arquivos PDF são permitidos.');
            return;
        }

        // Processa o arquivo PNG
        setArquivoSelecionado1(acceptedFiles[0]);


    }, []);

    const { getRootProps: getRootArquivoProps1, getInputProps: getInputArquivoProps1 } = useDropzone({
        onDrop: onDropArquivo1,
        multiple: false, // Apenas um arquivo pode ser selecionado
        accept: '.pdf', // Restringe a aceitação apenas para arquivos .pdf

    });

    const [open, setOpen] = useState(false);
    const [mensagem, setMensagem] = useState("");

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setArquivoSelecionado1(null);
        setMensagem('');
    };



    async function autenticar() {


    
        if (!arquivoSelecionado1) {
            alert("Selecione um arquivo PDF para a consulta!");
            return null;
        }


        const ipResponse = await axios.get('https://api.ipify.org?format=json');
        const clientIP = ipResponse.data.ip;

        console.log("Ip:", clientIP);

        const response = await axios.post(
            'https://www.googleapis.com/geolocation/v1/geolocate?key=' + globalConfig.mapKey,
            {
                considerIp: true,
                wifiAccessPoints: [],
                cellTowers: [],
                homeMobileCountryCode: 310,
                homeMobileNetworkCode: 410,
                radioType: 'wcdma',
                carrier: 'Vodafone',
            }
        );
        const { data } = response;

        console.log("dados: " + JSON.stringify(data));


        try {

            console.log("cadastrar chamado");

            const formData = new FormData();

            formData.append('arquivos', arquivoSelecionado1);
            formData.append('ip', clientIP);
            formData.append('latitude', data.location.lat);
            formData.append('longitude', data.location.lng);



            const response = await api.post('/v1/public/consultarautenticacao', formData);

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                let texto = "Sucesso!\n\nEste é um documento original emitido pelo Grupo Rosinetos!\n\nO documento é válido, caso ainda tenha dúvidas, contate-nós, abaixo especificamos nossos meios de contato";

                setMensagem(texto);
                handleOpen();
                setArquivoSelecionado1(null);
                setCaptchaVerified(false);
            } else {

                let texto = "Atenção!\n\nEste NÃO é um documento original emitido pelo Grupo Rosinetos!\n\nPedimos que entre em contato conosco por algum meio de contato especificado abaixo, não cumpra nenhum requisito deste documento, ele não é válido!";

                setMensagem(texto);
                handleOpen();
                setArquivoSelecionado1(null);
                setCaptchaVerified(false);

            }


        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");
            setCaptchaVerified(false);

        }



    }





    return (
        <div >
            <div style={{
                backgroundImage: width > 1200 ? `url(${capa_sobre})` : `url(${capa1mobile})`,
                backgroundSize: "contain",
                width: '100%',
                height: '100%',
            }} >

                <Navegador sobre={'underline'} corFundo={'rgba(255,255,255,1)'} travado={width > 900 ? true : false} corTexto={'black'} />
            </div>



            <Grid item xs={12} container
                direction='row'
                alignContent='center'
                justifyContent='center'

                style={{ margin: 50 }} >



                <Grid item xs={6} container
                    direction='row'
                    alignContent='center'
                    justifyContent='center'
                >

                    <Grid item xs={12} container
                        direction='row'
                        alignContent='center'
                        justifyContent='center'
                        style={{ paddingTop: 50 }}
                    >
                        <Typography variant="h4">
                            Validar Integridade de Arquivo
                        </Typography>
                    </Grid>


                    <Grid item xs={12} container
                        direction='row'
                        alignContent='center'
                        justifyContent='center'
                        style={{ paddingTop: 50 }}

                    >
                        <Typography variant="h6">
                            Nesta página, você pode verificar a integridade de um arquivo PDF de um documento assinado no Grupo Rosinetos.
                        </Typography>
                    </Grid>


                    <Grid item xs={12} container
                        direction='row'
                        alignContent='center'
                        justifyContent='center'
                        style={{ paddingTop: 50 }}
                    >
                        <Typography variant="h6">
                            Faça o upload do arquivo no espaço ao lado para verificar se ele foi gerado no Grupo Rosinetos.
                        </Typography>
                    </Grid>


                </Grid>

                <Grid item xs={6} container
                    direction='row'
                    alignContent='center'
                    justifyContent='center'
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        item xs={12}
                    >
                        {arquivoSelecionado1 ? (

                            <div
                                href={URL.createObjectURL(arquivoSelecionado1)}
                                onClick={() => window.open(URL.createObjectURL(arquivoSelecionado1), '_blank')}
                                onMouseOver={() => console.log('URL:', URL.createObjectURL(arquivoSelecionado1))}
                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', margin: 0, width: '200px', height: '200px' }}>
                                <img alt={'miniatura_pdf'} style={{ textAlign: 'center' }} src={miniatura_pdf}></img>
                            </div>


                        ) : (
                            <div {...getRootArquivoProps1()} style={dropzoneStyles}>
                                <input {...getInputArquivoProps1()} />
                                <Typography align="center" variant="h6">
                                    Upload do arquivo
                                </Typography>
                            </div>
                        )}
                    </Grid>

                 

                   
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        item xs={12}
                        style={{ paddingTop: 30 }}

                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => autenticar()}
                        > Consultar  </Button>
                    </Grid>

                </Grid>

            </Grid>

            <div >
                <Rodape />
            </div>


            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Informação</DialogTitle>
                <DialogContent dividers>
                    <Grid
                        item xs={12}
                        container
                        direction='row'
                        justifyContent='center'
                        alignItens='center'
                        style={{ textAlign: 'center' }}
                    >
                        <Grid
                            item xs={12}
                            container
                            direction='row'
                            justifyContent='center'
                            alignItens='center'
                            style={{ textAlign: 'center' }}
                        >
                            <Typography component="div" style={{ whiteSpace: 'pre-line' }}>{mensagem}</Typography>
                        </Grid>
                        <Grid
                            item xs={12}
                            container
                            direction='row'
                            justifyContent='center'
                            alignItens='center'
                            style={{ textAlign: 'center', color: 'black' }}
                        >
                            <span style={{ fontSize: 14, color: 'white', paddingTop: 30, color: 'black' }}>

                                <img alt="{telefone}"
                                    style={{ weight: 20, height: 20 }}
                                    src={'https://www.certsan.com.br/wp-content/uploads/2020/05/icone-telefone-1.png'}
                                />
                                (91) 9 8897-5705
                                <br></br> <br></br>
                                <img alt="{telefone}"
                                    style={{ weight: 20, height: 20, color: 'black' }}
                                    src={'https://cdn-icons-png.flaticon.com/512/3670/3670051.png'}
                                />
                                <a
                                    style={{ fontSize: 14, textDecoration: 'underline', color: 'black' }}
                                    href="https://api.whatsapp.com/send?1=pt_BR&phone=551125897073">

                                    (91) 3033-7575</a>

                                <br></br> <br></br>
                                <img alt="{telefone}"
                                    style={{ weight: 20, height: 20 }}
                                    src={'https://cdn-icons-png.flaticon.com/512/2504/2504727.png'}
                                />
                                <a href={"mailto:contato@gruporosinetos.com"} style={{ fontSize: 14, color: 'black' }}> contato@gruporosinetos</a>
                            </span>
                        </Grid>
                    </Grid>

                </DialogContent>



                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>

        </div >


    );
}

export default AutenticarDoc;

/*

import React, { useState, useRef, useEffect } from 'react';
import Rodape from '../../components/Rodape';
import Navegador from '../../components/NavBar';
import Cookies from 'js-cookie';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import './styles.scss';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import gestao from '../../assets/imgs/gestao.jpg';
import SockJsClient from 'react-stomp';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import Recorder from 'recorder-js';
import lamejs from 'lamejs'; // Biblioteca para codificar MP3
import MicRecorder from 'mic-recorder-to-mp3';



const useStyles = makeStyles((theme) => ({
    root: {
        height: '50vh',
    },

    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    entrada: {
        fontSize: 22
    },
}));


export default function AutenticarDoc() {

    const token = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhaXNsYW4xIiwiaWF0IjoxNzE1Mjg4MDk3LCJleHAiOjE3MTUzNzQ0OTd9.7QbVHcZyrR9shMJDvdYu3b_GckGGPd2_QbYLOyCumcdghT1-Ashz2b6TRu1J_2okJWWUaZgGZYpwiwNTrGt4Jg";
    const socket = new WebSocket("wss://pkaislan234-64091.portmap.io:64091/micmasterws?token=" + token);
    //const socket = new WebSocket("ws://192.168.100.8:10050/micmasterws?token=" + token);

    var Client = Stomp.over(socket);
    Client.debug = null

    const [text, setText] = useState();
    const [resposta, setResposta] = useState("");
    const [respostaAudio, setRespostaAudio] = useState("");





    useEffect(() => {



        connect();

    }, []);



    const [finalizar, setFinalizar] = useState(false);
    const [recordedUrl, setRecordedUrl] = useState('');
    const mediaStream = useRef(null);
    const mediaRecorder = useRef(null);
    var chunks;

    
    const options = {
        audioBitsPerSecond: 128000 // Reduza a qualidade do áudio para 128 kbps
    };

    const startRecording = async () => {
        console.log("start iniciado!");
        try {

            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaStream.current = stream;
            mediaRecorder.current = new MediaRecorder(stream, options);
            chunks = [];


            mediaRecorder.current.ondataavailable = (e) => {
                if (!finalizar) {
                    console.log("ondataavailable");


                    if (e.data.size > 0) {
                        chunks.push(e.data);
                        const audioBlob = new Blob(chunks, { type: chunks[0].type });

                        //console.log("a dados para enviar: " + e.data.size);
                        sendLast300msAudio(audioBlob); // Envia os dados para o servidor
                    } else {
                        console.log("sem dados para enviar");
                    }
                }

            };

            mediaRecorder.current.start();

            setInterval(() => {
                console.log("Intervalo");
                if (mediaRecorder.current.state === 'recording') {
                    console.log("esta gravando, solicitando dados...");
                    mediaRecorder.current.requestData(); // Solicita dados ao MediaRecorder
                } else {
                    console.log("nao esta gravando")
                }
            }, 1500); // Solicita dados a cada 1 segundo

            // setTimeout(stopRecording, 1000); // Grava por aproximadamente 1 segundo
        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    };

    const stopRecording = () => {
        //if (!finalizar) {
            mediaRecorder.current.stop(); // Para a gravação
           // startRecording(); // Inicia uma nova gravação imediatamente
        setFinalizar(true);
        disconnect();
        
    };


    const finalRecording = () => {
        console.log("chamou finalizar!");
        setFinalizar(true);
        disconnect();
        window.location.reload(); // Recarrega a página

    };


   // Função para extrair uma parte do buffer de áudio
const extractAudioBufferSlice = (audioBuffer, audioContext, sampleRate, startOffset, endOffset) => {
    const startFrame = Math.floor(startOffset * sampleRate);
    const endFrame = Math.floor(endOffset * sampleRate);
    const sliceLength = endFrame - startFrame;
    const channels = audioBuffer.numberOfChannels;
    const sliceBuffer = audioContext.createBuffer(channels, sliceLength, sampleRate);

    for (let channel = 0; channel < channels; channel++) {
        const channelData = audioBuffer.getChannelData(channel).subarray(startFrame, endFrame);
        sliceBuffer.copyToChannel(channelData, channel);
    }

    return sliceBuffer;
};

const sendLast300msAudio = async (audioBlob) => {
    try {
        const reader = new FileReader();
        reader.onload = async () => {
            const audioData = reader.result;
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();

            const audioBuffer = await audioContext.decodeAudioData(audioData);

            // Extrair os últimos 300ms do áudio
            const sampleRate = audioBuffer.sampleRate;
            const duration = audioBuffer.duration;
            const startOffset = Math.max(0, duration - 1.49); // Começa nos últimos 300ms
            const endOffset = duration;
            const last300msBuffer = extractAudioBufferSlice(audioBuffer, audioContext, sampleRate, startOffset, endOffset);

            // Converter os últimos 300ms para WAV
            const wavBlob = await audioBufferToWav(last300msBuffer);

            // Ler o conteúdo do Blob WAV como base64
            const base64data = await blobToBase64(wavBlob);

            // Enviar os últimos 300ms do áudio WAV para o servidor WebSocket
            Client.send("/app/audiostream", {}, base64data);
        };
        reader.readAsArrayBuffer(audioBlob);
    } catch (error) {
        console.error('Error converting audio to WAV:', error);
    }
};


    const sendAudioStream = async (audioBlob) => {
        try {
            const reader = new FileReader();
            reader.onload = async () => {
                const audioData = reader.result;

                // Converter o áudio para o formato WAV
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                const audioBuffer = await audioContext.decodeAudioData(audioData);
                const wavBlob = await audioBufferToWav(audioBuffer);

                // Ler o conteúdo do Blob WAV como base64
                const base64data = await blobToBase64(wavBlob);

                // Enviar o áudio WAV para o servidor WebSocket
                Client.send("/app/audiostream", {}, base64data);
            };
            reader.readAsArrayBuffer(audioBlob);
        } catch (error) {
            console.error('Error converting audio to WAV:', error);
        }
    };
    // Função para converter um Blob para base64
    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result.split(',')[1]);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    };


    const audioBufferToWav = (audioBuffer) => {
        return new Promise((resolve, reject) => {
            try {
                const sampleRate = 44100; // Taxa de amostragem fixa (44.1kHz)
                const bitsPerSample = 16; // Resolução fixa (16 bits)

                const bufferLength = audioBuffer.length * audioBuffer.numberOfChannels * (bitsPerSample / 8);
                const wavBuffer = new ArrayBuffer(44 + bufferLength);
                const wavView = new DataView(wavBuffer);

                // Set the WAV header
                writeString(wavView, 0, 'RIFF');
                wavView.setUint32(4, 36 + bufferLength, true);
                writeString(wavView, 8, 'WAVE');
                writeString(wavView, 12, 'fmt ');
                wavView.setUint32(16, 16, true); // PCM format
                wavView.setUint16(20, 1, true); // Audio format (PCM)
                wavView.setUint16(22, audioBuffer.numberOfChannels, true);
                wavView.setUint32(24, sampleRate, true); // Sample rate
                wavView.setUint32(28, sampleRate * audioBuffer.numberOfChannels * (bitsPerSample / 8), true); // Byte rate
                wavView.setUint16(32, audioBuffer.numberOfChannels * (bitsPerSample / 8), true); // Block align
                wavView.setUint16(34, bitsPerSample, true); // Bits per sample
                writeString(wavView, 36, 'data');
                wavView.setUint32(40, bufferLength, true);

                // Write the audio data
                writeAudioBuffer(wavView, 44, audioBuffer);

                // Create the Blob
                const wavBlob = new Blob([wavBuffer], { type: 'audio/wav' });
                resolve(wavBlob);
            } catch (error) {
                reject(error);
            }
        });
    };
    // Função auxiliar para escrever uma string em uma DataView
    const writeString = (view, offset, string) => {
        for (let i = 0; i < string.length; i++) {
            view.setUint8(offset + i, string.charCodeAt(i));
        }
    };

    // Função auxiliar para escrever um buffer de áudio em uma DataView
    const writeAudioBuffer = (view, offset, buffer) => {
        for (let i = 0; i < buffer.numberOfChannels; i++) {
            const channelData = buffer.getChannelData(i);
            for (let j = 0; j < channelData.length; j++) {
                const sample = Math.max(-1, Math.min(1, channelData[j])); // Clamp the sample value between -1 and 1
                view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true); // Convert the sample to 16-bit PCM
                offset += 2;
            }
        }
    };




    function connect() {
        Client.connect({}, function (frame) {
            console.log('Conectado: ' + frame);

           Client.subscribe('/topic/audio/response', function (message) {
                const chatMessage = JSON.parse(message.body);
                setRespostaAudio(chatMessage.message);
            });

            Client.subscribe('/topic/miccontroller/response', function (message) {
                const chatMessage = JSON.parse(message.body);
                setResposta(chatMessage.message);
            });
        });
    }

    function disconnect() {
        if (Client) {
            Client.disconnect();
            console.log('Conexão WebSocket fechada.');
        }
    }



    function sendMessage(e) {
        e.preventDefault();

        const message = {
            name: "aislan",
            message: text
        };

        Client.send("/app/miccontroller", {}, JSON.stringify(message));

    }






    return (
        <div >


            <div>
                <audio controls src={recordedUrl} />
                <button onClick={startRecording}>Start Recording</button>
                <button onClick={stopRecording}>Stop Recording</button>
            </div>




            <TextField
                id="text"
                variant="standard"
                name="text"
                fullWidth
                label="Texto"
                required
                autoComplete="text"
                value={text}
                onChange={e => setText(e.target.value)}


            />

            <Button style={{ marginTop: 50 }}
                variant="contained"
                color="primary"
                onClick={sendMessage}
            > Salvar  </Button>


            <Typography style={{ margin: 15 }} >
                <span style={{ fontSize: 18, textDecoration: 'underline' }}> {resposta} </span>
            </Typography>

        </div >
    );
}
*/