// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#arodape:hover {
  color: orange;
  font-weight: bold;
  border-radius: 5px;
  padding: 10px; }
`, "",{"version":3,"sources":["webpack://./src/components/Rodape/styles.scss"],"names":[],"mappings":"AACE;EACI,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,aAAa,EAAA","sourcesContent":["\r\n  #arodape:hover {\r\n      color: orange;\r\n      font-weight: bold;\r\n      border-radius: 5px;\r\n      padding: 10px;\r\n  }\r\n  \r\n\r\n\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
