import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import { useParams } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from '@mui/icons-material/Edit';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';

import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Skeleton from '@material-ui/lab/Skeleton';

import Paper from '@material-ui/core/Paper';


import api from '../../../../../../../services/api';
import NavegadorGestao from "../../../../../../../components/NavBarGestao";


import { Button } from "@material-ui/core";
import { styled } from '@material-ui/styles';


import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid';




export default function GerenciarInstalacao() {


    const history = useHistory();


    const [tipoFisica, setTipoFisica] = useState(true);
    const [tipoJuridica, setTipoJuridica] = useState(false);

    /* variaveis pessoa fisica */
    const [cpf, setCpf] = useState('');
    const [rg, setRg] = useState('');
    const [nome, setNome] = useState('');
    const [sobreNome, setSobrenome] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    /* variaveis pessoa fisica */



    /* variaveis de estado de cadastro de pessoa juridica*/
    const [razaoSocial, setRazaoSocial] = useState('');
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [cnpj, setCnpj] = useState('');
    /* variaveis de estado de cadastro de pessoa juridica*/


    /*variaveis gerais*/
    const [porte, setPorte] = useState('');
    const [atividade, setAtividade] = useState('');
    const [ie, setIe] = useState('');
    const [descricao, setDescricao] = useState('');
    const [email, setEmail] = useState('');
    /*variaveis gerais*/

    const [parametrosEndereco, setParametrosEndereco] = useState(
        {
            logradouro: "",
            numero: "",
            bairro: "",
            complemento: "",
            cidade: "",
            estado: "",
            cep: "",
            latitude: "",
            longitude: "",
        }
    );


    const [insumos, setInsumos] = useState([]);
    const [contatos, setContatos] = useState([]);

    /*variaveis de controle pessoa fisica */

    const [erroNome, setErroNome] = useState(false);
    const [textoErroNome, setTextoErroNome] = useState('');

    const [erroSobreNome, setErroSobreNome] = useState(false);
    const [textoErroSobreNome, setTextoErroSobreNome] = useState('');

    const [erroRg, setErroRg] = useState(false);
    const [textoErroRg, setTextoErroRg] = useState('');


    const [erroDataNascimento, setErroDataNascimento] = useState(false);
    const [textoErroDataNascimento, setTextoErroDataNascimento] = useState('');

    /*variaveis de controle pessoa fisica */


    /*variaveis de controle pessoa juridica */
    const [erroRazaoSocial, setErroRazaoSocial] = useState(false);
    const [textoErroRazaoSocial, setTextoErroRazaoSocial] = useState('');

    const [erroNomeFantasia, setErroNomeFantasia] = useState(false);
    const [textoErroNomeFantasia, setTextoErroNomeFantasia] = useState('');

    /*variaveis de controle pessoa juridica */



    /* variaveis  de controle gerais */

    /* variaveis  de controle gerais */


    const [cliente, setCliente] = useState(null);

    const [iconeMapa, setIconeMapa] = useState(0);



    const [loading, setLoading] = useState(true);
    const [objetoGerenciado, setObjetoGerenciado] = useState([]);

    const { idInstalacao } = useParams();
    const [idCliente, setIdCliente] = useState(useParams());

    const [projetos, setProjetos] = useState([]);
    const [equipamentos, setEquipamentos] = useState([]);
    const [height, setHeight] = useState(0);


    function checkDimenssoes() {

        var altura = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;


        setHeight(altura * 0.75);

    }

    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });











    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/cliente/instalacoes/listarporid/" + idInstalacao, {
                headers: headers
            }).then(function (response) {
                setObjetoGerenciado(response.data);
                setEquipamentos(response.data.equipamentos);

                var id_cliente_pai = response.data.id_cliente_pai;
                setIdCliente(id_cliente_pai);
                console.log("Id cliente pai: " + id_cliente_pai);

                if (response.data.tipo_instalacao === 0) {
                    setTipoFisica(true);
                    setTipoJuridica(false);

                } else {
                    setTipoFisica(false);
                    setTipoJuridica(true);
                }

                setContatos(response.data.contatos);


                setCpf(response.data.cpf);
                setRg(response.data.rg);
                setNome(response.data.nome);
                setSobrenome(response.data.sobrenome);
                setDataNascimento(response.data.nascimento);

                // Atribuindo valores da resposta à pessoa jurídica
                setRazaoSocial(response.data.razao_social);
                setNomeFantasia(response.data.nome_fantasia);
                setCnpj(response.data.cnpj);

                // Atribuindo valores gerais
                setPorte(response.data.porte);
                setAtividade(response.data.atividade);
                setIe(response.data.ie);
                setDescricao(response.data.descricao);
                setEmail(response.data.email);

                setIconeMapa(response.data.icone_mapa);

                setParametrosEndereco(prevState => ({
                    ...prevState,
                    'logradouro': response.data.logradouro,
                    'numero': response.data.numero,
                    'bairro': response.data.bairro,
                    'cidade': response.data.cidade,
                    'estado': response.data.estado,
                    'cep': response.data.cep,
                    'latitude': response.data.latitude,
                    'longitude': response.data.longitude,
                    'nome_mapa': response.data.nome_mapa,
                }));




                api.get("v1/protected/projetos/listarporinstalacao/" + idInstalacao, {
                    headers: headers
                }).then(function (response) {
                    setProjetos(response.data)

                    console.log(" Objetos: " + response);

                    //listar o cliente
                    api.get("/v1/protected/retornardadoscliente/" + id_cliente_pai, {
                        headers: headers
                    }).then(function (response) {
                        setCliente(response.data);

                        setLoading(false);
                    });


                });

            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }


    function returnDescricaoCliente(cliente) {
        var texto =
            (cliente.tipo_cliente === 0 ? (cliente.nome + " " + cliente.sobrenome) : cliente.nome_fantasia) + " -" +
            (cliente.tipo_cliente === 0 ? (" " + cliente.cpf) : (" " + cliente.cnpj));

        return texto;
    }




    useEffect(() => {
        checkDimenssoes();
        listarInformacoes();


    }, []);






    function validarRazaoSocial() {
        if (razaoSocial?.length > 0) {
            setErroRazaoSocial(false);
            setTextoErroRazaoSocial('');
            return true;
        } else {
            setErroRazaoSocial(true);
            setTextoErroRazaoSocial("Razão Social Inválida");
            return false;
        }
    }

    function validarNomeFantasia() {
        if (nomeFantasia?.length > 0) {
            setErroNomeFantasia(false);
            setTextoErroNomeFantasia('');
            return true;
        } else {
            setErroNomeFantasia(true);
            setTextoErroNomeFantasia("Nome Fantasia Inválido");
            return false;
        }
    }

    function validarNome() {
        if (nome?.length > 0) {
            setErroNome(false);
            setTextoErroNome('');
            return true;
        } else {
            setErroNome(true);
            setTextoErroNome("Nome muito curto");
            return false;
        }
    }





    function validarSobreNome() {
        if (sobreNome?.length > 0) {
            setErroSobreNome(false);
            setTextoErroSobreNome('');
            return true;
        } else {
            setErroSobreNome(true);
            setTextoErroSobreNome("Sobrenome muito curto");
            return false;
        }
    }

    function validarRg() {
        if (rg?.length > 0) {
            setErroRg(false);
            setTextoErroRg('');
            return true;
        } else {
            setErroRg(true);
            setTextoErroRg("Rg muito curto");
            return false;
        }
    }






    function validarData(data) {
        const dateFormat = "DD/MM/YYYY";
        const teste = moment(data, dateFormat, true).isValid();

        if (teste) {
            setErroDataNascimento(false);
            setTextoErroDataNascimento("")
        } else {
            setErroDataNascimento(true);
            setTextoErroDataNascimento("Data de Nascimento Inválida!")
        }
        return teste;
    }





    async function atualizar() {

        if (tipoFisica) {
            //cadastrar pessoa fisica

            validarRg();
            validarData(dataNascimento);
            validarNome();
            validarSobreNome();

            console.log(validarData(dataNascimento));
            //prosseguir cadastro
            if (validarRg() && validarData(dataNascimento) && validarNome() &&
                validarSobreNome()) {
                console.log("cadastro aceito!")

                try {
                    const cadastro_instalacao_pf = {
                        tipo_instalacao: 0,
                        nome: nome,
                        sobrenome: sobreNome,
                        nascimento: dataNascimento,
                        rg: rg,
                        descricao: descricao,
                        logradouro: parametrosEndereco.logradouro,
                        numero: parametrosEndereco.numero,
                        bairro: parametrosEndereco.bairro,
                        cidade: parametrosEndereco.cidade,
                        estado: parametrosEndereco.estado,
                        cep: parametrosEndereco.cep,
                        latitude: parametrosEndereco.latitude,
                        longitude: parametrosEndereco.longitude,
                        nome_mapa: parametrosEndereco.nome_mapa,
                        icone_mapa: iconeMapa,
                    }

                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }

                    const response = await api.put('/v1/protected/clientes/instalacoes/atualizar/' + idInstalacao, cadastro_instalacao_pf,
                        { headers: headers });

                    const cadastro_salvo = response.data;
                    if (cadastro_salvo) {
                        alert("Instalacao atualizado com sucesso!")

                        window.location.reload();

                    } else {
                        alert("Erro de Conexão, tente novamente mais tarde");
                    }
                } catch (_err) {
                    console.log("erro ao cadastrar: " + _err);
                    alert("Erro de Conexão, tente novamente mais tarde");

                }



            } else {
                console.log("cadastro rejeitado!")
            }



        } else {
            //cadastrar pessoa juridica

            validarRazaoSocial();
            validarNomeFantasia();


            //prosseguir cadastro
            if (validarRazaoSocial() && validarNomeFantasia) {
                console.log("cadastro pj aceito!")

                try {

                    console.log("cadastrar chamado");
                    const cadastro_instalacao_pj = {
                        tipo_instalacao: 1,
                        razao_social: razaoSocial,
                        nome_fantasia: nomeFantasia,
                        porte: porte,
                        atividade: atividade,
                        descricao: descricao,
                        logradouro: parametrosEndereco.logradouro,
                        numero: parametrosEndereco.numero,
                        bairro: parametrosEndereco.bairro,
                        cidade: parametrosEndereco.cidade,
                        estado: parametrosEndereco.estado,
                        cep: parametrosEndereco.cep,
                        latitude: parametrosEndereco.latitude,
                        longitude: parametrosEndereco.longitude,
                        nome_mapa: parametrosEndereco.nome_mapa,
                        icone_mapa: iconeMapa,
                    }

                    const headers = {
                        'Authorization': 'Bearer ' + Cookies.get("token")
                    }

                    const response = await api.put('/v1/protected/clientes/instalacoes/atualizar/' + idInstalacao, cadastro_instalacao_pj,
                        { headers: headers });

                    const cadastro_salvo = response.data;
                    if (cadastro_salvo) {
                        alert("Instalacao atualizada com sucesso!")
                        window.location.reload();

                    } else {
                        alert("Erro de Conexão, tente novamente mais tarde");
                    }
                } catch (_err) {
                    console.log("erro ao cadastrar: " + _err);
                    alert("Erro de Conexão, tente novamente mais tarde");

                }



            } else {
                console.log("cadastro pj rejeitado!")
            }

        }
    }




    const handleChange1 = (event) => {
        setTipoFisica(true);
        setTipoJuridica(false);
    };

    const handleChange2 = (event) => {
        setTipoFisica(false);
        setTipoJuridica(true);
    };



    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);

    }


    const handleChangeIndex = (index) => {
        setValue(index);
    };



    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }



    const handleClickGerenciar = (event, cellValues) => {


        window.location.href = "/gestao/administracao/instalacaos/gerenciarinstalacao/instalacao/gerenciarinstalacao/" + cellValues.row.id_instalacao;


    };




    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const handleRowClick = (param, event) => {
        event.stopPropagation();
    };



    function returnDescricaoCotacao(cotacao) {

        var texto =
            "Insumo: " +
            cotacao.insumo.nome +
            "\nMarca/Modelo: " + cotacao.insumo.marca + "/" + cotacao.insumo.modelo +
            "\nCódigo Fornecedor: " + cotacao.insumo.codigo_fornecedor +
            "\nDescrição: " + cotacao.insumo.descricao +
            "\nUnidade: " +
            cotacao.unidade +
            "\nData Cotação: " +
            cotacao.data;
        return texto;
    }

    function returnDescricaoStatus(status_item) {
        var texto = "";

        if (status_item === 0) {
            texto = "Disponível";
        }
        else if (status_item === 1) {
            texto = "Alugado";
        }
        else if (status_item === 2) {
            texto = "Vendido";
        }
        else if (status_item === 3) {
            texto = "Emprestado";
        }

        return texto
    }

    function returnDescricaoFornecedor(fornecedor) {
        var texto = "Fornecedor_ID: " + fornecedor.id_fornecedor + "\nCPF/CNPJ: " + (fornecedor.tipo_fornecedor === 0 ? fornecedor.cpf : fornecedor.cnpj) +
            "\nNome/Nome Fantasia: " + (fornecedor.tipo_fornecedor === 0 ? (fornecedor.nome + " " + fornecedor.sobrenome) : fornecedor.nome_fantasia);

        return texto;
    }

    function returnDescricaoOrdemCompra(ordem) {
        var texto = "Ordem_ID: " + ordem.id_ordem_compra + "\nFornecedor: " + returnDescricaoFornecedor(ordem.fornecedor) + "\nDescrição da Ordem: " + ordem.descricao + "\nData prevista de entrega: " + ordem.data_prevista_entrega;
        return texto;
    }


    function returnDescricaoEntradaEstoque(entrada_estoque) {
        var texto = "Entrada_Estoque_ID: " + entrada_estoque.id_entrada_estoque;
        return texto;
    }


    const handleClickEdicao = (cellValues) => {


        window.location.href = "/gestao/operacional/almoxarifado/estoque/gerenciaritemestoque/" + cellValues.row.id_item_estoque;


    };



    const columnsDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 200,
            renderCell: (cellValues) => {
                return (


                    <IconButton color="error" onClick={() => handleClickEdicao(cellValues)}>
                        <SettingsIcon />
                    </IconButton>

                )
            },
        },


        {
            headerName: 'ID',
            field: 'id_item_estoque',
            id: 1,
            headerClassName: 'cabecalho_azul',
        },


        {
            headerName: 'Insumo',
            field: 'cotacao',
            id: 2,
            width: 300,
            headerClassName: 'cabecalho_azul',
            valueGetter: (params) => {

                var cotacao = params.row.item.cotacao;
                return returnDescricaoCotacao(cotacao);

            },
        },

        {
            headerName: 'Status',
            field: 'status',
            id: 3,
            width: 150,
            headerClassName: 'cabecalho_azul',
            valueGetter: (params) => {

                var status_item = params.row.status_item;
                return returnDescricaoStatus(status_item);

            },
        },


        {
            headerName: 'Saída Estoque',
            field: 'saida_estoque',
            id: 4,
            width: 150,
            headerClassName: 'cabecalho_azul',


        },

    ];




    function novoContato() {
        window.location.href = "/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/" + idInstalacao + "/" + idCliente + "/cadastrarcontato";

    }


    const handleClickEdicaoContato = (cellValues) => {
        window.location.href = "/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/" + idInstalacao + "/" + idCliente + "/editarcontato/" + cellValues.row.id_contato;

    };


    const columnsContatosDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 200,
            renderCell: (cellValues) => {
                return (

                    <IconButton onClick={() => handleClickEdicaoContato(cellValues)}>
                        <EditIcon />
                    </IconButton>



                )
            },
        },


        {
            headerName: 'ID',
            field: 'id_contato',
            id: 1,
            headerClassName: 'cabecalho_azul',
        },


        {
            headerName: 'Nome',
            field: 'nome_contato',
            id: 2,
            width: 300,
            headerClassName: 'cabecalho_azul',

        },

        {
            headerName: 'Cargo',
            field: 'cargo_contato',
            id: 3,
            width: 300,
            headerClassName: 'cabecalho_azul',

        },
        {
            headerName: 'Celular',
            field: 'celular_contato',
            id: 4,
            width: 300,
            headerClassName: 'cabecalho_azul',

        },

        {
            headerName: 'Fixo',
            field: 'fixo_contato',
            id: 5,
            width: 300,
            headerClassName: 'cabecalho_azul',

        },

        {
            headerName: 'E-mail',
            field: 'e_mail_contato',
            id: 6,
            width: 300,
            headerClassName: 'cabecalho_azul',

        },
        {
            headerName: 'Envio de E-mail',
            field: 'emails',
            id: 7,
            width: 600,
            headerClassName: 'cabecalho_azul',
            renderCell: (cellValues) => {
                var email_principal = cellValues.row.email_principal;
                var email_copia = cellValues.row.email_copia;


                return (
                    <Typography style={{ fontSize: 16 }} variant="h6">
                        {email_principal === 1 ? "É destinátario de e-mail" : email_copia === 1 ? "É destinátario em Cópia" : "Não"}
                    </Typography>
                )
            },
        },
        {
            headerName: 'Descrição',
            field: 'descricao_contato',
            id: 7,
            width: 600,
            headerClassName: 'cabecalho_azul',

        },




    ];





    const handleClickGerenciarProjeto = (cellValues) => {

        window.location.href = "/gestao/administracao/projetos/editaroprojeto/" + cellValues.row.id_projeto + "/1/" + idInstalacao + "/" + idCliente;



    };

    const handleClickEditarEquipamento = (cellValues) => {

        window.location.href = "/gestao/administracao/equipamentos/editarequipamento/" + cellValues.row.id_equipamento + "/1/" + idInstalacao + "/" + idCliente;

    };


    


    function returnDescricaoInsumo(equipamento) {

        let insumo = equipamento.row.item_estoque.item.insumo;
        let textoComQuebraDeLinha = "Descrição Principal: " + insumo.descricao;

        if (insumo.variacoes && insumo.variacoes.length > 0) {
            textoComQuebraDeLinha += insumo.variacoes

                .map((variacao, index) => (
                    `<br />*Variação-> Fornecedor: ${variacao.nome_amigavel_fornecedor} Variação: ${variacao.descricao}${index !== insumo.variacoes.length - 1 ? '\n' : ''}`
                )).join('');
        }

        return textoComQuebraDeLinha;
        
    }

    const columnsEquipamentosGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 100,
            renderCell: (cellValues) => {
                return (
                    <IconButton color="error" onClick={() => handleClickEditarEquipamento(cellValues)}>
                        <SettingsIcon />
                    </IconButton>
                );
            },
        },
        {
            headerName: 'ID',
            field: 'id_equipamento',
            id: 1,
            width: 50,
            headerClassName: 'cabecalho_azul',
        },

        {
            headerName: 'Status',
            field: 'status',
            id: 2,
            minWidth: 100,
            flex: 1,
            headerClassName: 'cabecalho_azul',
            valueFormatter: (params) => {
                var status = params.value;
                var texto;
                if (status === 0) {
                    texto = "INATIVO";
                } else if (status === 1) {
                    texto = "ATIVO";
                }
                return texto;
            },
        },
        {
            headerName: 'Insumo',
            field: 'descricao_insumo',
            id: 3,
            width: 400,
            headerClassName: 'cabecalho_azul',
            renderCell: (cellValues) => {
                return (
                    <Typography
                        variant="h7"
                    >
                        {returnDescricaoInsumo(cellValues)}
                    </Typography>

                );
            },
        },

        {
            headerName: 'Tipo Equipamento',
            field: 'tipo_equipamento_area_tecnica',
            id: 4,
            minWidth: 200,
            width: 200,
            flex: 1,
            headerClassName: 'cabecalho_azul',
            renderCell: (cellValues) => {
    /*
                //0 pgm
	//10 medidor
	//20 controlador de acesso
	//30 modulo sonoro
	//40 fonte vdc
	
	//70 central
	//80 camera
	//90 sensor
	//100 dvr
	//110 nvr
    */

                var status = cellValues.row.item_estoque.item.insumo.tipo_equipamento_area_tecnica;
                var texto;
                if (status === 0) {
                    texto = "PGM";
                } else if (status === 10) {
                    texto = "MEDIDOR DE CONSUMO";
                } else if (status === 20) {
                    texto = "CONTROLADOR DE ACESSO";
                } else if (status === 30) {
                    texto = "MÓDULO SONORO";
                } else if (status === 40) {
                    texto = "FONTE VDC";
                } else if (status === 70) {
                    texto = "CENTRAL";
                } else if (status === 80) {
                    texto = "CÂMERA";
                }else if (status === 90) {
                    texto = "SENSOR";
                }else if (status === 100) {
                    texto = "DVR";
                }else if (status === 110) {
                    texto = "NVR";
                }
                return texto;


                return (
                    <Typography
                        variant="h7"
                    >
                        {texto}
                    </Typography>

                );
            },
           
        },
        {
            headerName: 'Id do Item do Estoque',
            field: 'id_item_estoque',
            id: 3,
            width: 400,
            headerClassName: 'cabecalho_azul',
            renderCell: (cellValues) => {

                var id = cellValues.row.item_estoque.id_item_estoque;

                return (
                    <Typography
                        variant="h7"
                    >
                        {id}
                    </Typography>

                );
            },
        },



    ];



    const handleIconeMapa = (event) => {
        setIconeMapa(event.target.value);
    };


    const columnsProjetoDataGrid = [
        {
            field: 'acoes',
            headerName: 'Ações',
            headerClassName: 'cabecalho_marrom_claro',
            sortable: false,
            width: 100,
            renderCell: (cellValues) => {
                return (


                    <IconButton color="error" onClick={() => handleClickGerenciarProjeto(cellValues)}>
                        <SettingsIcon />
                    </IconButton>
                );
            },
        },
        {
            headerName: 'ID',
            field: 'id_projeto',
            id: 1,
            width: 50,
            headerClassName: 'cabecalho_azul',
        },


        {
            headerName: 'Status',
            field: 'status_projeto',
            id: 2,
            minWidth: 120,
            flex: 1,
            headerClassName: 'cabecalho_azul',
            valueFormatter: (params) => {
                var status = params.value;
                var texto;
                if (status === 0) {
                    texto = "Aberto"
                } else if (status === 1) {
                    texto = "Em Ánalise";
                } else if (status === 2) {
                    texto = "Em Correção";
                } else if (status === 3) {
                    texto = "Aprovado";
                } else if (status === 4) {
                    texto = "Recusado";
                }

                return texto;
            },
        },


        {
            headerName: 'Descriçao',
            field: 'descricao_projeto',
            id: 3,
            minWidth: 500,
            flex: 1, // Use flex instead of width for flexible width
            headerClassName: 'cabecalho_azul',
        },

        {
            field: 'usuario_criador',
            id: 4,
            headerName: 'Criador',
            minWidth: 400,
            flex: 1,
            headerClassName: 'cabecalho_azul',
            renderCell: (params) => {
                var usuario = params.value;
                var rawDate = params.row.data_hora_criacao;
                const formattedDate = moment(rawDate).format('DD/MM/YYYY HH:mm');
                var texto = "Criado por " + (usuario.nome_amigavel) + " em " + formattedDate;

                return (
                    <Typography
                        variant="h8"
                    >
                        {texto}
                    </Typography>
                );
            },

        },

    ];


    function novoProjeto() {
        window.location.href = "/gestao/administracao/projetos/cadastrarprojeto/1/" + idCliente + "/" + idInstalacao;

    }

    function novoEquipamento() {
        window.location.href = "/gestao/administracao/equipamentos/cadastrarequipamento/1/" + idCliente + "/" + idInstalacao;

    }



    return (
        <div>

            <NavegadorGestao
                BaseDados={true}
                Clientes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <div style={{ paddingTop: 5 }}>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <React.Fragment >

                        <Grid
                            item xs={12}
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent={"center"}
                        >

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                align="flex-start"
                                item xs={12}
                                style={{ width: '100%', margin: 0, padding: 0 }}
                            >

                                <a href={"/gestao/administracao/clientes/gerenciarcliente/" + cliente.id_cliente} style={{ fontSize: 12, color: 'black', marginRight: '5px' }}>{"< " + returnDescricaoCliente(cliente)}</a>

                            </Grid>


                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >


                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                            style={{ width: '100%', margin: 0, padding: 0 }}
                                        >
                                            <Tab label="Especificações" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Contatos" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Projetos" style={{ backgroundColor: '#1C1C1C' }} />
                                            <Tab label="Equipamentos" style={{ backgroundColor: '#1C1C1C' }} />


                                        </Tabs>



                                    </AppBar>

                                </Box>
                            </Grid>

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >

                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                >
                                    <Grid value={value} index={0}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                    >

                                        <Grid
                                            item xs={12}
                                            alignItems="center"
                                            justifyContent="center"
                                            style={{ padding: 15 }}
                                            container  >




                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                                item xs={12}
                                            >
                                                <Typography  >
                                                    Tipo Pessoa:
                                                </Typography>
                                                <FormControlLabel style={{ paddingLeft: 10 }} control={<Checkbox checked={tipoFisica}
                                                    onChange={handleChange1} />} label="Pessoa Física" />
                                                <FormControlLabel control={<Checkbox checked={tipoJuridica}
                                                    onChange={handleChange2} />} label="Pessoa Jurídica" />


                                            </Grid>



                                            <Collapse style={{ width: '100%' }} in={tipoJuridica} timeout="auto" unmountOnExit>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    item xs={12}
                                                    spacing={2}>

                                                    <Grid item xs={4}>
                                                        <TextField

                                                            variant="standard"
                                                            name="cnpj"
                                                            label="CNPJ"
                                                            required
                                                            id="cnpj"
                                                            value={cnpj}
                                                            fullWidth
                                                        />
                                                    </Grid>

                                                    <Grid item xs={4} >
                                                        <TextField
                                                            error={erroRazaoSocial}
                                                            helperText={textoErroRazaoSocial}
                                                            variant="standard"
                                                            name="razaoSocial"
                                                            label="Razão Social"
                                                            required
                                                            id="razaoSocial"
                                                            autoComplete="razaoSocial"
                                                            value={razaoSocial}
                                                            onChange={e => setRazaoSocial(e.target.value)}
                                                            fullWidth

                                                        />
                                                    </Grid>

                                                    <Grid item xs={4} >
                                                        <TextField
                                                            error={erroNomeFantasia}
                                                            helperText={textoErroNomeFantasia}
                                                            variant="standard"
                                                            name="nomeFantasia"
                                                            label="Nome Fantasia"
                                                            required
                                                            id="nomeFantasia"
                                                            value={nomeFantasia}
                                                            onChange={e => setNomeFantasia(e.target.value)}
                                                            fullWidth

                                                        />
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        item xs={12}
                                                        spacing={2}>

                                                        <Grid item xs={4} >

                                                            <TextField

                                                                variant="standard"
                                                                name="porte"
                                                                label="Porte"
                                                                id="porte"
                                                                value={porte}
                                                                onChange={e => setPorte(e.target.value)}
                                                                fullWidth

                                                            />
                                                        </Grid>

                                                        <Grid item xs={4} >
                                                            <TextField
                                                                variant="standard"
                                                                name="atividade"
                                                                label="Atividade"
                                                                id="atividade"
                                                                autoComplete="atividade"
                                                                value={atividade}
                                                                onChange={e => setAtividade(e.target.value)}
                                                                fullWidth

                                                            />
                                                        </Grid>

                                                        <Grid item xs={4}  >
                                                            <TextField

                                                                variant="standard"
                                                                name="ie"
                                                                label="Inscrição Estadual"
                                                                id="ei"
                                                                value={ie}
                                                                onChange={e => setIe(e.target.value)}
                                                                fullWidth

                                                            />
                                                        </Grid>


                                                    </Grid>
                                                </Grid>
                                            </Collapse>

                                            <Collapse style={{ width: '100%' }} in={tipoFisica} timeout="auto" unmountOnExit>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    item xs={12}
                                                    spacing={2}>
                                                    <Grid item xs={4}  >

                                                        <TextField

                                                            variant="standard"
                                                            name="cpf"
                                                            label="CPF"
                                                            required
                                                            id="cpf"
                                                            value={cpf}
                                                            fullWidth

                                                        />
                                                    </Grid>

                                                    <Grid item xs={4}  >
                                                        <TextField
                                                            error={erroRg}
                                                            helperText={textoErroRg}
                                                            variant="standard"
                                                            name="rg"
                                                            label="RG"
                                                            required
                                                            id="rg"
                                                            value={rg}
                                                            onChange={e => setRg(e.target.value)}
                                                            fullWidth

                                                        />
                                                    </Grid>


                                                    <Grid item xs={4}  >
                                                        <TextField
                                                            error={erroDataNascimento}
                                                            helperText={textoErroDataNascimento}
                                                            variant="standard"
                                                            name="dataNascimento"
                                                            label="Data Nascimento"
                                                            required
                                                            id="dataNascimento"
                                                            value={dataNascimento}
                                                            onChange={e => setDataNascimento(e.target.value)}
                                                            fullWidth

                                                        />
                                                    </Grid>

                                                    <Grid item xs={4}  >
                                                        <TextField
                                                            error={erroNome}
                                                            helperText={textoErroNome}
                                                            variant="standard"
                                                            name="nome"
                                                            label="Nome"
                                                            required
                                                            id="nome"
                                                            value={nome}
                                                            onChange={e => setNome(e.target.value)}
                                                            fullWidth

                                                        />
                                                    </Grid>

                                                    <Grid item xs={4}  >
                                                        <TextField
                                                            error={erroSobreNome}
                                                            helperText={textoErroSobreNome}
                                                            variant="standard"
                                                            name="sobrenome"
                                                            label="Sobrenome"
                                                            required
                                                            id="sobrenome"
                                                            value={sobreNome}
                                                            onChange={e => setSobrenome(e.target.value)}
                                                            fullWidth

                                                        />
                                                    </Grid>

                                                </Grid>
                                            </Collapse>





                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12}
                                                style={{ paddingTop: 15 }}
                                                spacing={2}>

                                                <Grid item xs={8} >
                                                    <TextField
                                                        variant="standard"
                                                        name="descricao"
                                                        label="Descrição"
                                                        id="descricao"
                                                        value={descricao}
                                                        onChange={e => setDescricao(e.target.value)}
                                                        fullWidth
                                                        multiline
                                                        rows={4}

                                                    />
                                                </Grid>
                                            </Grid>


                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12}
                                                alignItems="center"
                                                justifyItems="center"
                                                style={{ paddingTop: 80 }}
                                            >
                                                <Grid style={{ backgroundColor: 'whitesmoke', padding: 20 }} component={Paper} elevation={12}
                                                    container
                                                    direction="row"
                                                    item xs={12}
                                                    alignItems="center"
                                                >
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        item xs={12}
                                                    >
                                                        <Grid
                                                            container
                                                            direction="row" spacing={2}
                                                            item xs={12}>

                                                            <Typography style={{ margin: 15 }} >
                                                                <span style={{ fontSize: 22, textDecoration: 'underline' }}>Dados de Endereço: </span>
                                                            </Typography>


                                                            <Grid item xs={12}>
                                                                <TextField

                                                                    id="logradouro"
                                                                    variant="standard"
                                                                    name="logradouro"
                                                                    fullWidth
                                                                    label="Logradouro"
                                                                    value={parametrosEndereco.logradouro}
                                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'logradouro': e.target.value }))}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={2}>
                                                                <TextField

                                                                    id="numero"
                                                                    variant="standard"
                                                                    name="numero"
                                                                    fullWidth
                                                                    label="Número"
                                                                    value={parametrosEndereco.numero}
                                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'numero': e.target.value }))}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={5}>
                                                                <TextField

                                                                    id="bairro"
                                                                    variant="standard"
                                                                    name="bairro"
                                                                    fullWidth
                                                                    label="Bairro"
                                                                    value={parametrosEndereco.bairro}
                                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'bairro': e.target.value }))}
                                                                />
                                                            </Grid>



                                                            <Grid item xs={5}>
                                                                <TextField

                                                                    id="complemento"
                                                                    variant="standard"
                                                                    name="complemento"
                                                                    fullWidth
                                                                    label="Complemento"
                                                                    value={parametrosEndereco.complemento}
                                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'complemento': e.target.value }))}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={4}>
                                                                <TextField

                                                                    id="cidade"
                                                                    variant="standard"
                                                                    name="cidade"
                                                                    fullWidth
                                                                    label="Cidade"
                                                                    value={parametrosEndereco.cidade}
                                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'cidade': e.target.value }))}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={4}>
                                                                <TextField

                                                                    id="estado"
                                                                    variant="standard"
                                                                    name="estado"
                                                                    fullWidth
                                                                    label="Estado"
                                                                    value={parametrosEndereco.estado}
                                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'estado': e.target.value }))}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={4}>
                                                                <TextField

                                                                    id="cep"
                                                                    variant="standard"
                                                                    name="cep"
                                                                    fullWidth
                                                                    label="CEP"
                                                                    value={parametrosEndereco.cep}
                                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'cep': e.target.value }))}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <Typography style={{ margin: 15 }} >
                                                                    <span style={{ fontSize: 22, textDecoration: 'underline' }}>Dados de Mapa: </span>
                                                                </Typography>
                                                            </Grid>

                                                            <Grid
                                                                container
                                                                direction="row"
                                                                item xs={12} spacing={2}
                                                            >
                                                                <Grid item xs={4}>
                                                                    <TextField

                                                                        id="nome_mapa"
                                                                        variant="standard"
                                                                        name="nome_mapa"
                                                                        fullWidth
                                                                        label="Nome Mapa"
                                                                        value={parametrosEndereco.nome_mapa}
                                                                        onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'nome_mapa': e.target.value }))}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={4}
                                                                    container
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id="icone_mapa">Ícone Mapa </InputLabel>
                                                                            <Select
                                                                                labelId="icone_mapa"
                                                                                id="icone_mapa"
                                                                                value={iconeMapa}
                                                                                onChange={handleIconeMapa}
                                                                                label="Ícone Mapa "
                                                                            >
                                                                                <MenuItem value={0}>Casa</MenuItem>
                                                                                <MenuItem value={1}>Loja</MenuItem>
                                                                                <MenuItem value={2}>Sub-Estação</MenuItem>

                                                                            </Select>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>


                                                            </Grid>





                                                            <Grid item xs={4}>
                                                                <TextField

                                                                    id="latitude"
                                                                    variant="standard"
                                                                    name="latitude"
                                                                    fullWidth
                                                                    label="Latitude"
                                                                    value={parametrosEndereco.latitude}
                                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'latitude': e.target.value }))}
                                                                />
                                                            </Grid>

                                                            <Grid item xs={4}>
                                                                <TextField

                                                                    id="longitude"
                                                                    variant="standard"
                                                                    name="longitude"
                                                                    fullWidth
                                                                    label="Longitude"
                                                                    value={parametrosEndereco.longitude}
                                                                    onChange={e => setParametrosEndereco(prevState => ({ ...prevState, 'longitude': e.target.value }))}
                                                                />
                                                            </Grid>


                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                item xs={10}
                                            >
                                                <Button style={{ marginTop: 50 }}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={atualizar}
                                                > Atualizar  </Button>
                                            </Grid>

                                        </Grid>


                                    </Grid>

                                    <Grid value={value} index={1}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                    >

                                        <>
                                            <DataGrid
                                                pagination
                                                checkboxSelection
                                                rows={contatos}
                                                getRowId={(row) => row.id_contato}
                                                columns={columnsContatosDataGrid}
                                                disableColumnResize={false}
                                                onCellClick={handleCellClick}
                                                onRowClick={handleRowClick}
                                                components={{
                                                    Toolbar: CustomToolbar,
                                                }}
                                            />
                                        </>

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            item xs={12}
                                        >
                                            <Button style={{ marginTop: 50 }}
                                                variant="contained"
                                                color="primary"
                                                onClick={novoContato}
                                            > Novo Contato  </Button>
                                        </Grid>

                                    </Grid>


                                    <Grid value={value} index={2}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                    >

                                        <Grid
                                            container
                                            direction="row"
                                            item xs={12}
                                            alignItems="center"
                                            justifyContent="flex-end"
                                            style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                        >
                                            <Button style={{ margin: 5 }}
                                                variant="contained"
                                                color="primary"
                                                onClick={novoProjeto}
                                            >
                                                Novo Projeto
                                            </Button>
                                        </Grid>



                                        <div>
                                            <div style={{ backgroundColor: 'white' }}>
                                                {projetos !== null && (
                                                    <>
                                                        <DataGrid
                                                            pagination
                                                            checkboxSelection
                                                            rows={projetos}
                                                            getRowId={(row) => row.id_projeto}
                                                            columns={columnsProjetoDataGrid}
                                                            disableColumnResize={false}
                                                            onCellClick={handleCellClick}
                                                            onRowClick={handleRowClick}
                                                            components={{
                                                                Toolbar: CustomToolbar,
                                                            }}
                                                        />



                                                    </>

                                                )}
                                            </div>
                                        </div>




                                    </Grid>


                                    <Grid value={value} index={3}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                    >

                                        <Grid
                                            container
                                            direction="row"
                                            item xs={12}
                                            alignItems="center"
                                            justifyContent="flex-end"
                                            style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                        >
                                            <Button style={{ margin: 5 }}
                                                variant="contained"
                                                color="primary"
                                                onClick={novoEquipamento}
                                            >
                                                Novo Equipamento
                                            </Button>
                                        </Grid>



                                        <div>
                                            <div style={{ backgroundColor: 'white' }}>
                                                {equipamentos !== null && (
                                                    <>
                                                        <DataGrid
                                                            pagination
                                                            checkboxSelection
                                                            rows={equipamentos}
                                                            getRowId={(row) => row.id_equipamento}
                                                            columns={columnsEquipamentosGrid}
                                                            disableColumnResize={false}
                                                            onCellClick={handleCellClick}
                                                            onRowClick={handleRowClick}
                                                            components={{
                                                                Toolbar: CustomToolbar,
                                                            }}
                                                        />
                                                    </>

                                                )}
                                            </div>
                                        </div>
                                    </Grid>





                                </SwipeableViews>
                            </Grid>
                        </Grid>




                    </React.Fragment>
                }


            </div>

        </div >
    );
}



