import React, { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import globalConfig from '../../../../configs/globalConfigs';
import AppBar from '@mui/material/AppBar';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SockJsClient from 'react-stomp';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import Close from '@material-ui/icons/Close'

import Cookies from 'js-cookie';
import { MenuItem, Select, InputLabel, FormControl, IconButton } from '@mui/material';
import {
    FormLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';

import { useHistory } from "react-router-dom";
import moment from 'moment';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';

import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';
import {
    GoogleMap,
    Marker,
    LoadScript,
    OverlayView,
    StandaloneSearchBox,
    DirectionsService,
    DirectionsRenderer,
    DistanceMatrixService,
    MaxZoomService, Circle, Polygon
} from "@react-google-maps/api";

import {
    MaterialReactTable
} from 'material-react-table';



import SettingsIcon from '@mui/icons-material/Settings';


import api from '../../../../services/api';


import NavegadorGestao from "../../../../components/NavBarGestao";
import "./MapPage.css";


import mini_icone_carro from '../../../../assets/imgs/mini_icone_carro.png';

import mini_icone_moto from '../../../../assets/imgs/mini_icone_moto.png';

import icone_origem from '../../../../assets/imgs/icone_origem.png';
import icone_destino from '../../../../assets/imgs/icone_destino.png';
import icone_parada from '../../../../assets/imgs/icone_parada.png';


import icone_pgm from '../../../../assets/imgs/icone_pgm.png';

import icone_medidor from '../../../../assets/imgs/icone_medidor.png';

import icone_subestacao from '../../../../assets/imgs/icone_subestacao.png';
import icone_casa from '../../../../assets/imgs/icone_casa.png';
import icone_loja from '../../../../assets/imgs/icone_loja.png';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '1000px',

        backgroundColor: 'white',
    },
    toolbar: {

    },
    toolbarIcon: {

    },
    appBar: {

    },
    appBarShift: {

    },
    menuButton: {

    },
    menuButtonHidden: {

    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {

    },
    drawerPaperClose: {

    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        display: 'flex',


    },
    container: {
        display: 'flex',
        height: '1000px',

        backgroundColor: 'green',


    },
    paper: {

    },
    fixedHeight: {

    },
}));


const libraries = ["places"];



export default function AreaTecnica() {



    const classes = useStyles();
    const history = useHistory();


    const [value, setValue] = React.useState(0);


    const [showTable, setShowTable] = useState(false);



    const [definingPerimeter, setDefiningPerimeter] = useState(false); // Indica se está definindo perímetros
    const [perimeterPoints, setPerimeterPoints] = useState([]); // Armazena os pontos do perímetro
    const [currentPerimeter, setCurrentPerimeter] = useState(1); // Controla qual perímetro está sendo definido (P1 a P4)



    const handleMapClick = (event) => {
        if (definingPerimeter && currentPerimeter <= 4) {
            const newPoint = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
                label: `Perímetro ${currentPerimeter}`
            };
            setPerimeterPoints([...perimeterPoints, newPoint]); // Adiciona o novo ponto ao estado
            setCurrentPerimeter(currentPerimeter + 1); // Avança para o próximo perímetro

            // Se todos os perímetros forem definidos, para o processo
            if (currentPerimeter === 4) {
                setDefiningPerimeter(false);
                console.log("Todos os perímetros definidos:", perimeterPoints);
            }
        } else {
            event.preventDefault(); // Impede o menu de contexto padrão do navegador
            event.stopPropagation(); // Impede a propagação do evento para o mapa
        }
    };



    const handleMenuClick = (index) => {
        // Aqui você pode adicionar lógica para lidar com cliques em outros itens
        // Além de exibir a tabela ao clicar no ícone "Instalações"
        if (index === 0) {
            setShowTable(!showTable); // Alterna a visibilidade da tabela
        }
    };



    const [map, setMap] = useState();
    const mapRef = useRef(null);



    const [lat, setLat] = useState();
    const [lng, setLng] = useState();



    const onMapLoad = (map) => {
        setMap(map);
        mapRef.current = map; // Armazena a instância do mapa
        setGoogleLoaded(true);
    };

    const handleMarkerClick = (lat, lng) => {

        if (mapRef.current) {
            // Ajusta o zoom e centraliza o mapa no ponto clicado
            mapRef.current.setZoom(21); // Zoom máximo
            mapRef.current.panTo({ lat, lng });
        }
    };



    const options = {
        mapTypeId: 'satellite',
        streetViewControl: true,
        fullscreenControl: true,
        styles: [
            {
                featureType: "poi", // Oculta pontos de interesse (POI)
                elementType: "labels",
                stylers: [{ visibility: "off" }],
            },
        ],
    };


    const [endereco, setEndereco] = useState();

    const [instalacoes, setInstalacoes] = useState([]);

    const marker = [
        {
            position: { lat: lat, lng: lng },
            title: "Unidade Base",
        },
    ];

    const [markers, setMarkers] = useState([]);


    const containerStyle = {
        width: '100%',
        height: '100%'
    };



    const [configuracoes, setConfiguracoes] = useState();
    const [loading, setLoading] = useState();

    async function listarInformacoes() {
        try {

            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("/v1/protected/configuracoes", {
                headers: headers
            }).then(function (response) {
                setConfiguracoes(response.data);

                const lati = parseFloat(response.data.unidade_base.latitude);
                const lngi = parseFloat(response.data.unidade_base.longitude);

                setLat(lati);
                setLng(lngi);

                console.log("Lat: " + lati);
                console.log("Lng: " + lngi);


                const logradouro = response.data.unidade_base.logradouro;
                const numero = response.data.unidade_base.numero;
                const bairro = response.data.unidade_base.bairro;
                const complemento = response.data.unidade_base.complemento;
                const cidade = response.data.unidade_base.cidade;
                const estado = response.data.unidade_base.estado;
                const cep = response.data.unidade_base.cep;

                const end = `${logradouro}, ${numero}, ${bairro}, ${cidade}, ${estado} - ${cep}`;
                setEndereco(end);
                console.log("Endereço: " + end);

                //listar instalacoes
                api.get("/v1/protected/clientes/instalacoes/listartodas", {
                    headers: headers
                }).then(function (response) {

                    setInstalacoes(response.data);

                    console.log("Concluido!");
                    setLoading(false);

                });
            });
        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar informações: " + _err)

        }

    }



    const [googleLoaded, setGoogleLoaded] = useState(false);

    const handleLoadGoogle = () => {
        setGoogleLoaded(true);
    };




    function checkDimenssoes() {


        var largura = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        setWidth(largura)

    }

    const [width, setWidth] = useState(0);



    window.addEventListener('resize', function (event) {
        checkDimenssoes();
    });


    useEffect(() => {


        listarInformacoes();
        // checkDimenssoes();




    }, []);





    useEffect(() => {
        const token = Cookies.get('token');
        const ws = new WebSocket("wss://gruporosinetos.com/monitoramentowebsocket?token=" + token);

        ws.onopen = function () {
            console.log("WebSocket connection open");
            //ws.send(JSON.stringify({ destination: "/app/mensagem" }));
        };



    }, []);

    function processarIcone(tipo) {
        if (tipo === 0) {
            return icone_pgm;
        } else if (tipo === 10) {
            return icone_medidor;
        }
    }



    function processarIconeInstalacao(tipo) {
        if (tipo === 0) {
            return icone_casa;
        } else if (tipo === 1) {
            return icone_loja;
        } else if (tipo == 2) {
            return icone_subestacao;
        }
    }


    const LocalComponent = ({ id_instalacao, lat, lng, onClick, tipo_icone, nome_mapa, status }) => {

        const [showMenu, setShowMenu] = useState(false);



        const handleContextMenu = (event) => {
            event.preventDefault(); // Impede o menu de contexto padrão do navegador
            event.stopPropagation(); // Impede a propagação do evento para o mapa
            setShowMenu(!showMenu); // Alterna a visibilidade do menu
        };


        const handleClickGerenciar = () => {
            const url = "/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/" + id_instalacao + "/0";
            window.open(url, '_blank'); // Abre o link em uma nova guia
            setShowMenu(!showMenu);
        };



        const handleClickDefinirPerimetro = (event) => {
            event.stopPropagation();
            setDefiningPerimeter(true);
            setCurrentPerimeter(1);
            setPerimeterPoints([]); // Resetar perímetros ao começar um novo
            setShowMenu(false);
        };





        return (
            <OverlayView
                position={{ lat, lng }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={(width, height) => ({
                    x: -(width / 2),
                    y: -(height / 2),
                })}
            >
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        transform: "translate(-50%, -50%)",
                        height: "50px",
                        width: "50px",
                    }}
                    onClick={(event) => {
                        event.stopPropagation(); // Impede a propagação do evento para o mapa
                        onClick(lat, lng);
                    }}
                    className={status === 0 ? "piscar" : ""}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 0 }}>

                        <img
                            alt={"icone"}
                            style={{
                                textAlign: 'center',
                                width: '40px',  // Define a largura da imagem
                                height: '40px'  // Define a altura da imagem
                            }}
                            src={processarIconeInstalacao(tipo_icone)}
                            onContextMenu={handleContextMenu}
                        />
                        <span style={{
                            color: 'white',  // Cor do texto
                            fontSize: '10px',  // Ajuste o tamanho do texto conforme necessário
                            textShadow: '1px 1px 0px black, -1px -1px 0px black, 1px -1px 0px black, -1px 1px 0px black',
                            fontWeight: 'bold'
                        }}>
                            {nome_mapa}
                        </span>
                    </div>
                    {showMenu && (
                        <div style={{
                            position: 'absolute',
                            backgroundColor: 'white',
                            border: '1px solid black',
                            borderRadius: '4px',
                            padding: '10px',
                            top: '50px', // Ajusta a posição vertical do menu
                            left: '50%', // Ajusta a posição horizontal do menu
                            transform: 'translateX(-50%)',
                            zIndex: 1000, // Garante que o menu fique acima de outros elementos
                            boxShadow: '0 2px 6px rgba(0,0,0,0.3)', // Adiciona sombra ao menu
                        }}>
                            <IconButton color="error" onClick={() => handleClickGerenciar()}>
                                <SettingsIcon />
                                <span style={{ marginLeft: '8px' }}>Gerenciar</span>
                            </IconButton>
                            <IconButton color="error" onClick={(event) => handleClickDefinirPerimetro(event)}>
                                <SettingsIcon />
                                <span style={{ marginLeft: '8px' }}>Definir Perimetro</span>
                            </IconButton>
                        </div>
                    )}



                </div>
            </OverlayView>
        );
    };

    const EquipamentoComponent = ({ lat, lng, tipo, onClick, status }) => {
        return (
            <OverlayView
                position={{ lat, lng }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={(width, height) => ({
                    x: -(width / 2),
                    y: -(height / 2),
                })}
            >
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        transform: "translate(-50%, -50%)",
                        height: "50px",
                        width: "50px",
                    }}
                    onClick={(event) => {
                        event.stopPropagation(); // Impede a propagação do evento para o mapa
                        onClick(lat, lng);
                    }}
                    className={status === 0 ? "piscar" : ""}
                >
                    <div style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
                        <img
                            alt={"icone"}
                            style={{
                                textAlign: 'center',
                                width: '40px',  // Define a largura da imagem
                                height: '40px'  // Define a altura da imagem
                            }}
                            src={processarIcone(tipo)}
                        />
                    </div>
                </div>
            </OverlayView>
        );
    };






    const columnsInstalacoes = useMemo(
        () => [

            {
                accessorKey: 'id_instalacao', //normal accessorKey
                header: 'ID',
                size: 25,
                maxHeight: 30,
            },

            {
                accessorKey: 'nome_mapa', //normal accessorKey
                header: 'Local',
                size: 100,
                maxHeight: 30,
            },

        ],
        [],
    );


    const polygonOptions = {
        strokeColor: 'blue',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: 'blue',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
    };



    return (
        <div  >
            <NavegadorGestao
                Operacional={true}
                Rotas={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            {loading ?
                <Skeleton animation={"wave"} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :
                <div className={classes.content}>
                    <React.Fragment >
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            item xs={12}
                        >


                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                align="center"
                                item xs={12}
                                style={{ position: 'absolute', top: 5, left: 0, width: '100%', height: '100%', zIndex: 0 }}
                            >
                                <LoadScript
                                    googleMapsApiKey={globalConfig.mapKey}
                                    libraries={libraries}
                                    loadingElement={<div>Loading...</div>}
                                >
                                    <GoogleMap
                                        onLoad={onMapLoad}
                                        center={{
                                            lat, lng
                                        }}
                                        mapContainerStyle={containerStyle}
                                        options={options}
                                        zoom={5}

                                        onClick={handleMapClick}
                                    >

                                        <Marker key={marker.title} position={marker.position} title={marker.title} />



                                        {instalacoes.map((instalacao) => {
                                            const latInstalacao = parseFloat(instalacao.latitude);
                                            const lngInstalacao = parseFloat(instalacao.longitude);
                                            const instalacaoValida = !isNaN(latInstalacao) && !isNaN(lngInstalacao);

                                            return (
                                                instalacaoValida && (
                                                    <React.Fragment key={instalacao.id_instalacao}>
                                                        <LocalComponent
                                                            id_instalacao={instalacao.id_instalacao}
                                                            lat={latInstalacao}
                                                            lng={lngInstalacao}
                                                            onClick={handleMarkerClick}
                                                            tipo_icone={instalacao.icone_mapa}
                                                            nome_mapa={instalacao.nome_mapa}
                                                        />
                                                        {instalacao.equipamentos.map((equipamento) => {
                                                            const latEquipamento = parseFloat(equipamento.latitude);
                                                            const lngEquipamento = parseFloat(equipamento.longitude);
                                                            const equipamentoValido = !isNaN(latEquipamento) && !isNaN(lngEquipamento);

                                                            return (
                                                                equipamentoValido && (
                                                                    <EquipamentoComponent
                                                                        key={equipamento.id_equipamento}
                                                                        lat={latEquipamento}
                                                                        lng={lngEquipamento}
                                                                        tipo={equipamento.item_estoque.item.insumo.tipo_equipamento_area_tecnica}
                                                                        onClick={handleMarkerClick}
                                                                    />
                                                                )
                                                            );
                                                        })}
                                                    </React.Fragment>
                                                )
                                            );
                                        })}


                                        {/* Exibe os marcadores dos perímetros definidos */}
                                        {perimeterPoints.map((point, index) => (
                                            <Marker
                                                key={index}
                                                position={{ lat: point.lat, lng: point.lng }}
                                                label={point.label}
                                            />
                                        ))}



                                        {perimeterPoints.length === 4 && (
                                            <Polygon
                                                paths={perimeterPoints.map(point => ({ lat: point.lat, lng: point.lng }))}
                                                options={polygonOptions}
                                            />
                                        )}



                                    </GoogleMap>
                                </LoadScript>
                            </Grid>

                        </Grid>


                    </React.Fragment>
                </div>

            }


            <Drawer
                variant="permanent"
                anchor="left"
                sx={{
                    top: '100px', // Ajuste conforme a altura do NavegadorGestao
                    height: 'calc(100vh - 100px)',
                    '& .MuiDrawer-paper': {
                        position: 'fixed',
                        top: '100px', // Ajuste conforme a altura do NavegadorGestao
                        width: '300px', // Defina a largura do Drawer conforme necessário
                    },
                }}
            >
                <List>
                    <ListItem button onClick={() => handleMenuClick(0)}>
                        <ListItemIcon><DashboardIcon /></ListItemIcon>
                        <ListItemText primary="Instalações" />
                    </ListItem>

                    {showTable && (
                        <Box
                            sx={{
                                width: '100%',
                                padding: '16px', // Espaçamento opcional
                            }}
                        >
                            <MaterialReactTable
                                columns={columnsInstalacoes}
                                data={instalacoes}
                                enableTopToolbar={false}
                                enableBottomToolbar={false}
                                enablePagination={false}
                                enableColumnFilters={true}
                                enableColumnActions={false}
                                sortable={false}
                                enableSorting={false}
                                enableRowSelection={false}
                                color={'black'}
                                paginationDisplayMode={'pages'}
                                getRowId={(row) => row.id_instalacao}
                                initialState={{
                                    pagination: { pageSize: 1000, pageIndex: 0 },
                                    showGlobalFilter: true,
                                    color: 'black'
                                }}
                                muiTableContainerProps={{ sx: { maxHeight: "1000px", color: 'black' } }}
                                muiTableBodyRowProps={{
                                    sx: {
                                        height: 40,
                                        color: 'black' // Define a cor do texto para preto
                                    },
                                }}
                                enableColumnFilterModes={false}
                                enableExpandAll={true}
                                enableColumnPinning={true}
                                enableColumnResizing={true}
                                muiPaginationProps={{
                                    rowsPerPageOptions: [20, 50, 100],
                                    variant: 'outlined',
                                }}
                                enableFullScreenToggle={true}
                                muiTablePaperProps={({ table }) => ({
                                    style: {
                                        zIndex: table.getState().isFullScreen ? 1000 : undefined,
                                        color: 'black',
                                    },
                                })}
                            />
                        </Box>
                    )}

                </List>
            </Drawer>
        </div >
    );
}





