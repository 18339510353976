import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Cookies from 'js-cookie';
import Typography from '@material-ui/core/Typography';

import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import {
    Box,
    Tooltip
} from '@mui/material';


import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";

import EditIcon from '@mui/icons-material/Edit';

import { Button } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@mui/material/AppBar';
import TextField from '@material-ui/core/TextField';


import {
    MaterialReactTable,

} from 'material-react-table';




function novoCliente() {
    window.location.href = "/gestao/administracao/clientes/cadastrarcliente/"

}

export default function Clientes() {



    const [loading, setLoading] = useState(true);
    const [clientes, setClientes] = useState([]);









    const [idUsuarioAAlterar, setIdUsuarioAlterar] = useState(0);
    const [alertOpen, setAlertOpen] = useState(false);
    const [mensagem, setMensagem] = useState('');
    const [buttonOkDisabled, setButtonOkDisabled] = useState(false);

    const handleOpenAlert = (id) => {
        setIdUsuarioAlterar(id);
        setMensagem('');

        setAlertOpen(true);
    };


    async function handleCloseAlert() {
        setButtonOkDisabled(true);

        try {


            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const id_usuario_logado = Cookies.get('id_usuario');

            const response = await api.put('/v1/protected/clientes/mudarStatus/' + id_usuario_logado + "/" + idUsuarioAAlterar + "/" + mensagem,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {
                alert("Colaborador Atualizado com sucesso!")



            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
            }
        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            alert("Erro de Conexão, tente novamente mais tarde");

        }


        setIdUsuarioAlterar(0);
        setMensagem('');
        setAlertOpen(false);
        setButtonOkDisabled(false);


        listarClientes();

    }



    async function listarClientes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }

            await api.get("v1/protected/clientes/listarTodos", {
                headers: headers
            }).then(function (response) {

                const updatedData = response.data.map(item => {
                    // Modificar o atributo 'status'
                    item.status = item.status === 1 ? 'ATIVO' : 'DESATIVADO';

                    item.tipo_identificacao = item.tipo_cliente === 0 ? item.cpf : item.cnpj;

                    item.nome_pesquisa = item.tipo_cliente === 0 ?
                    ( (item.nome ? item.nome : "")  + " " + (item.sobrenome ?  item.sobrenome : "")  ) :
                    ( (item.nome_fantasia ? (item.razao_social ? item.razao_social : "") : ""  ));

                    item.endereco_completo =  item.logradouro + ", nº " + item.numero + ", " + item.bairro + ", " + item.cidade + " " + item.estado + " CEP: " +item.cep

                    return item;
                });

                setClientes(updatedData);

                setLoading(false);

            });


        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar clientes: " + _err)

        }

    }


    useEffect(() => {




        listarClientes();


    }, []);




    const handleClickGerenciar = (cellValues) => {


        window.location.href = "/gestao/administracao/clientes/gerenciarcliente/" + cellValues.id_cliente;


    };








    const columns = useMemo(
        () => [

            {
                accessorKey: 'id_cliente', //normal accessorKey
                header: 'ID',
                size: 60,
            },
            {
                accessorKey: 'status',
                header: 'Status',
                size: 80,
                Cell: ({ renderedCellValue }) => (
                    <span >
                        {renderedCellValue}
                    </span>
                ),
            },

            {
                header: 'CPF/CNPJ',
                size: 150,
                accessorKey: 'tipo_identificacao',
                Cell: ({ renderedCellValue }) => {
                    return (
                        <span >
                            {renderedCellValue}
                        </span>
                    )
                },
            },

            {
                header: 'Nome',
                accessorKey: 'nome_pesquisa',
                size: 350,
                Cell: ({ renderedCellValue }) => {
                    return (
                        <span>
                            {renderedCellValue}
                        </span>
                    )

                },
            },

            {
                header: 'Endereço',
                accessorKey: 'endereco_completo',
                size: 450,
                Cell: ({ renderedCellValue }) => {
                    return (
                        <span style={{ fontSize: 12 }} >
                            {
                                renderedCellValue
                            }
                        </span>
                    )

                },
            },



        ],
        [],
    );



    return (
        <div>

            <NavegadorGestao
                BaseDados={true}
                Clientes={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />

            <div style={{ padding: 5 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    item xs={12}
                >
                    <Button style={{ margin: 5 }}
                        variant="contained"
                        color="primary"
                        onClick={novoCliente}
                    >
                        Novo Cliente
                    </Button>
                </Grid>
                {loading ?
                    <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                    </Skeleton>
                    :

                    <div>
                        {clientes && (
                            <MaterialReactTable
                                columns={columns}
                                data={clientes}
                                enableGlobalFilterModes
                                enablePagination={true}
                                enableRowActions
                                paginationDisplayMode={'pages'}
                                getRowId={(row) => row.id_cliente}
                                enableTopToolbar={true}
                                enableBottomToolbar={false}
                                enableColumnFilters={true}
                                enableColumnActions={false}
                                sortable={true}
                                enableSorting={true}
                                enableRowSelection={false}


                                enableColumnFilterModes={true}
                                enableExpandAll={true}
                                enableColumnPinning={true}
                                enableColumnResizing={true}




                                initialState={{
                                    pagination: { pageSize: 50, pageIndex: 0 }, // Correção da sintaxe aqui
                                    showGlobalFilter: true,
                                    density: 'compact',
                                }}
                                muiTableContainerProps={{ sx: { maxHeight: "1000px" } }}

                                muiPaginationProps={{
                                    rowsPerPageOptions: [50, 100, 200],
                                    variant: 'outlined',
                                }}
                                enableFullScreenToggle={true}
                                renderDetailPanel={({ row }) => (
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            left: '30px',
                                            maxWidth: '1000px',
                                            position: 'sticky',
                                            width: '100%',
                                        }}
                                    >
                                    </Box>
                                )}
                                renderRowActions={({ row }) => (
                                    <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'row', alignItems: 'center' }}>
                                        
                                        <Tooltip title="Gerenciar">
                                            <IconButton color="error" onClick={() => handleClickGerenciar(row.original)}>
                                                <SettingsIcon />
                                            </IconButton>
                                        </Tooltip>

                                    </Box>
                                )}
                            />
                        )}


                    </div>
                }


            </div>



            <Dialog
                fullScreen={false}
                open={alertOpen}
                onClose={handleCloseAlert}
                aria-labelledby="confirm-dialog"
                BackdropProps={{
                    invisible: true,
                }}
            >
                <DialogTitle
                    style={{ backgroundColor: 'black', color: 'white' }}
                    id="confirm-dialog"> Mudar Status de Colaborador</DialogTitle>
                <DialogContent
                    style={{ backgroundColor: 'black' }}
                >
                    <div>
                        <AppBar
                            style={{ backgroundColor: 'black', color: 'white' }}

                            sx={{ position: 'relative' }}>

                            <Grid container spacing={1}

                                style={{ backgroundColor: 'black', color: 'white' }}

                                item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    disable={buttonOkDisabled}
                                    id="mensagem"
                                    variant="standard"
                                    name="mensagem"
                                    fullWidth
                                    label="Mensagem"
                                    required
                                    autoComplete="mensagem"
                                    value={mensagem}
                                    onChange={e => setMensagem(e.target.value)}
                                    multiline
                                    rows={3}
                                    InputProps={{
                                        style: { color: 'white' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: 'white' },
                                    }}

                                />

                                <Grid
                                    disable={buttonOkDisabled}
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={12}
                                    onClick={(e) => handleCloseAlert()}
                                >
                                    <Button style={{ marginTop: 20 }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        ok
                                    </Button>
                                </Grid>
                            </Grid>
                        </AppBar>
                    </div>
                </DialogContent>

            </Dialog >
        </div>
    );
}



