import React, { useState, useEffect, useCallback } from 'react';
import Container from '@material-ui/core/Container';
import { useParams } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Button, Card, CardContent } from '@mui/material';
import { useHistory } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Skeleton from '@material-ui/lab/Skeleton';


// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import api from '../../../../../services/api';
import NavegadorGestao from "../../../../../components/NavBarGestao";

import miniatura_pdf from '../../../../../assets/imgs/miniatura_pdf.png';


const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: '20px 0',
};


export default function EditarEquipamento() {


    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);

    }


    const [insumos, setInsumos] = useState([]);
    const [insumoSelecionado, setInsumoSelecionado] = useState(null);


    const [modulosPgms, setModulosPgms] = useState(null);


    function handleSetInsumo(insumo) {
        setInsumoSelecionado(insumo);
    }

    const [moduloPgmSelecionado, setModuloPgmSelecionado] = useState(null);


    function returnDescricaoInsumo(insumo_) {

        if (flagBack === 0) {
            let insumo = insumo_.insumo;
            let textoComQuebraDeLinha = "Descrição Principal: " + insumo.descricao;

            if (insumo.variacoes && insumo.variacoes.length > 0) {
                textoComQuebraDeLinha += insumo.variacoes

                    .map((variacao, index) => (
                        `<br />*Variação-> Fornecedor: ${variacao.nome_amigavel_fornecedor} Variação: ${variacao.descricao}${index !== insumo.variacoes.length - 1 ? '\n' : ''}`
                    )).join('');
            }

            var texto =

                //"<br />Marca/Modelo: " + insumo.marca + "/" + insumo.modelo +
                // "<br />Código Fornecedor: " + insumo.codigo_fornecedor +
                //"<br />Tipo: " + insumo.tipo +
                //"<br />Categoria: " + insumo.categoria +
                textoComQuebraDeLinha + "<br />Estoque: " + (Array.isArray(insumo_.itens_estoque) ? insumo_.itens_estoque.length : 0) + " unidades";
            return texto;
        } else {
            let textoComQuebraDeLinha = "Descrição Principal: " + insumo_.descricao;

            if (insumo_.variacoes && insumo_.variacoes.length > 0) {
                textoComQuebraDeLinha += insumo_.variacoes

                    .map((variacao, index) => (
                        `<br />*Variação-> Fornecedor: ${variacao.nome_amigavel_fornecedor} Variação: ${variacao.descricao}${index !== insumo_.variacoes.length - 1 ? '\n' : ''}`
                    )).join('');
            }

            return textoComQuebraDeLinha;
        }
    }




    function returnDescricaoStatus(status_item) {
        var texto = "";

        if (status_item === 0) {
            texto = "Disponível";
        }
        else if (status_item === 1) {
            texto = "Alugado";
        }
        else if (status_item === 2) {
            texto = "Vendido";
        }
        else if (status_item === 3) {
            texto = "Emprestado";
        }

        return texto
    }



    function returnDescricaoItemEstoque(item) {

        return "ID: " + item.id_item_estoque + "<br />" +
            "Status: " + returnDescricaoStatus(item.status_item) + "<br />" +
            "Fornecedor: " + (item.item.cotacao.fornecedor.tipo_fornecedor === 0 ? (item.item.cotacao.fornecedor.nome + " " + item.item.cotacao.fornecedor.sobrenome) : item.item.cotacao.fornecedor.nome_fantasia) + "<br />";


    }







    const { flagBack } = useParams();
    const { idCliente } = useParams();
    const { idInstalacao } = useParams();
    const { idEquipamento } = useParams();



    //const { idEquipamento } = useParams();


    const handleChangeIndex = (index) => {
        setValue(index);
    };


    const [instalacao, setInstalacao] = useState(null);
    const [botaoClicado, setBotaoClicado] = useState(false);


    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [nomeMapa, setNomeMapa] = useState("");
    const [iconeMapa, setIconeMapa] = useState(0);

    const handleIconeMapa = (event) => {
        setIconeMapa(event.target.value);
    };


    function returnDescricaoModuloPgm(modulo) {
        var texto = "ID: " + modulo.id_modulo_pgm + "\n Código: " + modulo.codigo;
        return texto;
    }


    const salvar = () => {
        // Verifica se o botão já foi clicado
        if (!botaoClicado) {
            // Executa a lógica do clique aqui
            console.log('Botão clicado!');

            // Atualiza o estado para indicar que o botão foi clicado
            setBotaoClicado(true);
            funcaoSalvar();
        }
    };



    const history = useHistory();



    async function funcaoSalvar() {


        try {

            const cadastro = {
                latitude: latitude,
                longitude: longitude,
                nome_mapa: nomeMapa,
            }

            const headers = {
                'Authorization': 'Bearer ' + Cookies.get("token")
            }

            const response = await api.put('/v1/protected/equipamentos/atualizar/' + idEquipamento, cadastro,
                { headers: headers });

            const cadastro_salvo = response.data;
            if (cadastro_salvo) {

                alert("Equipamento Atualizado");
                if (parseInt(flagBack) === 1) {
                    history.push({

                        pathname: "/gestao/administracao/clientes/gerenciarcliente/instalacao/gerenciarinstalacao/" + idInstalacao + "/" + idCliente,
                    })
                }

            } else {
                alert("Erro de Conexão, tente novamente mais tarde");
                //setBotaoSalvarClickado(false);

            }

        } catch (_err) {
            console.log("erro ao cadastrar: " + _err);
            setBotaoClicado(false);
            alert("Erro de Conexão, tente novamente mais tarde");

        }



    }



    const [clienteSelecionado, setClienteSelecionado] = useState();

    const [clientes, setClientes] = useState();

    const [loading, setLoading] = useState(true);


    const [itemEstoqueSelecionado, setItemEstoqueSelecionado] = useState(null);

    const [equipamento, setEquipamento] = useState(null);


    const handleCliente = (event, cliente) => {

        setClienteSelecionado(cliente);
    };



    function returnDescricaoCliente(cliente) {
        console.log("Descricao de cliente chamada!");
        var texto = "ID: " + cliente.id_cliente + " " +
            (cliente.tipo_cliente === 0 ? ("CPF: " + cliente.cpf) : ("CNPJ: " + cliente.cnpj)) + " " +
            (cliente.tipo_cliente === 0 ? (cliente.nome + " " + cliente.sobrenome) : cliente.nome_fantasia);

        return texto;
    }


    function returnDescricaoInstalacao(instalacao) {
        var texto = "ID_Integrador: " + instalacao.identificador_integrador + "\n Instalacao_ID: " + instalacao.id_instalacao + "\n CPF/CNPJ: " + (instalacao.tipo_instalacao === 0 ? instalacao.cpf : instalacao.cnpj) +
            "\n Nome/Nome Fantasia: " + (instalacao.tipo_instalacao === 0 ? (instalacao.nome + " " + instalacao.sobrenome) : instalacao.nome_fantasia);

        return texto;
    }



    async function listarInformacoes() {

        setLoading(true);

        try {
            const token = Cookies.get('token');

            const headers = {
                'Authorization': 'Bearer ' + token
            }


            let id_cliente = parseInt(idCliente);
            let id_instalacao = parseInt(idInstalacao);
            let flag = parseInt(flagBack);


            if (flag === 0) {
                await api.get("v1/protected/clientes/listarTodos", {
                    headers: headers
                }).then(function (response) {
                    setClientes(response.data)
                    setLoading(false);


                });


            } else if (flag === 1) {
                console.log("Flag é " + flag);

                setClientes([]);

                await api.get("/v1/protected/retornardadoscliente/" + id_cliente, {
                    headers: headers
                }).then(function (response) {
                    console.log("Dados de cliente retornado!");
                    setClienteSelecionado(response.data);

                    //listar instalacao
                    api.get("/v1/protected/cliente/instalacoes/listarporid/" + idInstalacao, {
                        headers: headers
                    }).then(function (response) {
                        setInstalacao(response.data);
                        console.log("Instalacao retornada!");

                        //listar o equipamento
                        api.get("v1/protected/equipamentos/listarporid/" + idEquipamento, {
                            headers: headers
                        }).then(function (response) {
                            console.log("Item estoque retornado!");

                            setEquipamento(response.data);

                            setLatitude(response.data.latitude);
                            setLongitude(response.data.longitude);
                            setNomeMapa(response.data.nome_mapa);

                            setInsumoSelecionado(response.data.item_estoque.item.insumo)
                            setItemEstoqueSelecionado(response.data.item_estoque);

                            if(response.data.item_estoque != null && 
                                response.data.item_estoque.item.insumo.tipo_equipamento_area_tecnica === 0
                            ){
                            setModuloPgmSelecionado(response.data.modulo_pgm);
                            }
                            setLoading(false);

                        });




                    });
                });


            }

        } catch (_err) {
            // avisar('Houve um problema com o login, verifique suas credenciais! ' + cpf + " " + senha );
            console.log("Erro ao listar os Clientes: " + _err)
        }
    }

    useEffect(() => {

        listarInformacoes();


    }, []);



    function handleSetInstalacao(instalacao) {
        setInstalacao(instalacao);
    }



    return (
        <div>
            <NavegadorGestao
                BaseDados={true}
                Projetos={true}
                corFundo={'white'} travado={true} corTexto={'black'}
            />
            <div style={{ paddingTop: 5 }} />


            {loading ?
                <Skeleton animation={"wave"} width={'100%'} style={{ backgroundColor: '#48D1CC' }}>
                </Skeleton>
                :
                <Container >

                    <React.Fragment >
                        <Grid container spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >

                            <Grid
                                item xs={12}
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent={"center"}
                            >


                                <Box style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}>
                                    <AppBar position="static" >



                                        <Tabs
                                            value={value}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                            style={{ width: '100%', margin: 0, padding: 0 }}
                                        >
                                            <Tab label="Informações Iniciais" style={{ backgroundColor: '#1C1C1C' }} />


                                        </Tabs>

                                    </AppBar>

                                </Box>
                            </Grid>





                            <Grid
                                style={{ paddingTop: 30 }}
                                container
                                direction="row"
                                item xs={12}
                                alignItems="center"
                                justifyContent="center"
                                spacing={2}
                            >


                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    item xs={10}
                                >
                                    <Button style={{ margin: 5 }}
                                        variant="contained"
                                        color="primary"
                                        onClick={salvar}
                                        disabled={botaoClicado}
                                    > Atualizar  </Button>
                                </Grid>

                                <SwipeableViews
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                    style={{ backgroundColor: 'white', width: '100%', alignItems: 'center', }}
                                >

                                    <Grid
                                        value={value} index={0}
                                        style={{ paddingTop: 30 }}
                                        container
                                        direction="row"
                                        item xs={12}
                                        alignItems="center"
                                        justifyContent="center"
                                        spacing={2}
                                    >



                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            align="center"
                                            item xs={12}
                                            style={{ marginTop: 20 }}
                                        >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={5}
                                            >

                                                <Autocomplete
                                                    id="select-ordem-servico"
                                                    options={flagBack === 0 ? clientes : []}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(cliente) => returnDescricaoCliente(cliente).replace(/<br \/>/g, '\n')}
                                                    value={clienteSelecionado}
                                                    disabled={flagBack === 0 ? false : true}
                                                    onChange={handleCliente}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((cliente) => {
                                                            const cotacaoText = returnDescricaoCliente(cliente).toLowerCase();
                                                            return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Cliente " multiline minRows={3} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoCliente(option) }} />
                                                    )}

                                                />
                                            </Grid>
                                        </Grid>




                                        {clienteSelecionado &&
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                align="center"
                                                item xs={12}
                                                style={{ marginTop: 20 }}
                                            >
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="center"
                                                    align="center"
                                                    item xs={5}
                                                >

                                                    <Autocomplete
                                                        id="select-instalacao"
                                                        options={flagBack === 0 ? clienteSelecionado.instalacoes : []}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(instalacao) => returnDescricaoInstalacao(instalacao).replace(/<br \/>/g, '\n')}
                                                        value={instalacao}
                                                        disabled={flagBack === 0 ? false : true}
                                                        onChange={(e, v) => handleSetInstalacao(v)}
                                                        filterOptions={(options, { inputValue }) => {
                                                            const inputKeywords = inputValue.toLowerCase().split(" ");
                                                            return options.filter((instalacao) => {
                                                                const cotacaoText = returnDescricaoInstalacao(instalacao).toLowerCase();
                                                                return inputKeywords.every((keyword) => cotacaoText.includes(keyword));
                                                            });
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label=" Instalação " multiline minRows={3} />
                                                        )}
                                                        renderOption={(option) => (
                                                            <div dangerouslySetInnerHTML={{ __html: returnDescricaoInstalacao(option) }} />
                                                        )}

                                                    />
                                                </Grid>
                                            </Grid>
                                        }



                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            align="center"
                                            item xs={8}
                                        >

                                            <Autocomplete
                                                id="select-insumo"
                                                options={flagBack === 0 ? insumos : []}
                                                getOptionLabel={(insumo) => returnDescricaoInsumo(insumo).replace(/<br \/>/g, '\n')}
                                                value={insumoSelecionado}
                                                disabled={flagBack === 0 ? false : true}
                                                getOptionSelected={(option, value) => true}
                                                onChange={(e, v) => setInsumoSelecionado(v)}
                                                filterOptions={(options, { inputValue }) => {
                                                    const inputKeywords = inputValue.toLowerCase().split(" ");
                                                    return options.filter((insumo) => {
                                                        const insumoText = returnDescricaoInsumo(insumo).toLowerCase();
                                                        return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                    });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label=" Insumo " multiline minRows={2} />
                                                )}
                                                renderOption={(option) => (
                                                    <div dangerouslySetInnerHTML={{ __html: returnDescricaoInsumo(option) }} />
                                                )}
                                            />
                                        </Grid>


                                        {insumoSelecionado &&

                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                align="center"
                                                item xs={8}
                                            >

                                                <Autocomplete
                                                    id="select-item-estoque"
                                                    options={(insumoSelecionado && flagBack === 0) ? insumoSelecionado.itens_estoque : []}
                                                    getOptionLabel={(itemEstoque) => returnDescricaoItemEstoque(itemEstoque).replace(/<br \/>/g, '\n')}
                                                    value={itemEstoqueSelecionado}
                                                    disabled={flagBack === 0 ? false : true}
                                                    getOptionSelected={(option, value) => true}
                                                    disabled={flagBack === 0 ? false : true}
                                                    onChange={(e, v) => setItemEstoqueSelecionado(v)}
                                                    filterOptions={(options, { inputValue }) => {
                                                        const inputKeywords = inputValue.toLowerCase().split(" ");
                                                        return options.filter((insumo) => {
                                                            const insumoText = returnDescricaoItemEstoque(insumo).toLowerCase();
                                                            return inputKeywords.some((keyword) => insumoText.includes(keyword));
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label=" Item do Estoque " multiline minRows={2} />
                                                    )}
                                                    renderOption={(option) => (
                                                        <div dangerouslySetInnerHTML={{ __html: returnDescricaoItemEstoque(option) }} />
                                                    )}
                                                />
                                            </Grid>
                                        }

{insumoSelecionado && itemEstoqueSelecionado && (itemEstoqueSelecionado.item.insumo.tipo_equipamento_area_tecnica === 0) &&

<Grid
    container
    direction="column"
    justifyContent="center"
    align="center"
    item xs={8}
>

    <Autocomplete
        id="select-modulo_pgm"
        options={[]}
        getOptionLabel={(modulo) => returnDescricaoModuloPgm(modulo).replace(/<br \/>/g, '\n')}
        value={moduloPgmSelecionado}
        disabled={flagBack === 0 ? false : true}
        getOptionSelected={(option, value) => true}
        onChange={(e, v) => setModuloPgmSelecionado(v)}
        filterOptions={(options, { inputValue }) => {
            const inputKeywords = inputValue.toLowerCase().split(" ");
            return options.filter((insumo) => {
                const insumoText = returnDescricaoModuloPgm(insumo).toLowerCase();
                return inputKeywords.some((keyword) => insumoText.includes(keyword));
            });
        }}
        renderInput={(params) => (
            <TextField {...params} label="Módulo PGM " multiline minRows={2} />
        )}
        renderOption={(option) => (
            <div dangerouslySetInnerHTML={{ __html: returnDescricaoModuloPgm(option) }} />
        )}
    />
</Grid>
}

                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography style={{ margin: 15 }} >
                                                    <span style={{ fontSize: 22, textDecoration: 'underline' }}>Dados de Mapa: </span>
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12} spacing={2}
                                            >
                                                <Grid item xs={4}>
                                                    <TextField

                                                        id="nome_mapa"
                                                        variant="standard"
                                                        name="nome_mapa"
                                                        fullWidth
                                                        label="Nome Mapa"
                                                        value={nomeMapa}
                                                        onChange={e => setNomeMapa(e.target.value)}
                                                    />
                                                </Grid>

                                            </Grid>

                                            <Grid
                                                container
                                                direction="row"
                                                item xs={12} spacing={2}
                                            >
                                                <Grid item xs={4}>
                                                    <TextField

                                                        id="latitude"
                                                        variant="standard"
                                                        name="latitude"
                                                        fullWidth
                                                        label="Latitude"
                                                        value={latitude}
                                                        onChange={e => setLatitude(e.target.value)}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <TextField

                                                        id="longitude"
                                                        variant="standard"
                                                        name="longitude"
                                                        fullWidth
                                                        label="Longitude"
                                                        value={longitude}
                                                        onChange={e => setLongitude(e.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>


                                        </Grid>


                                    </Grid>


                                </SwipeableViews>


                            </Grid>

                        </Grid>


                    </React.Fragment>


                </Container>
            }

        </div >
    );
}


